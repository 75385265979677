import {
  ChangeUserAPIParams,
  CreateNewUserAPIParams,
  UserSettingsResponse,
  UsersResponseData,
} from 'types/adminService';
import { type Balance } from 'types/user';
import $api from 'utils/http';
import { hashString } from '../utils/crypto';

export async function getUsers(): Promise<UsersResponseData> {
  return $api.get('admin/user-managment/users');
}

export async function getBalanceByUserId(id: number) {
  return $api.get('admin/user-managment/users/balance', {
    params: {
      id,
    },
  });
}

export async function updateUserBalance({ id, balance }: { id: number; balance: Balance[] }) {
  return $api.put(`admin/user-managment/users/${id}/balance`, {
    balance,
  });
}

export async function createNewUser(data: CreateNewUserAPIParams) {
  data.password = hashString(data.password);
  return $api.post('admin/user-managment/users', data);
}

export async function changeUserInfo({ id, data }: { id: number; data: ChangeUserAPIParams }) {
  if (data.password) {
    data.password = hashString(data.password);
  }

  return $api.put(`admin/user-managment/users/${id}`, data);
}

export async function deleteUser(id: number) {
  return $api.delete(`admin/user-managment/users/${id}`);
}

export async function setUserGameSettings({
  id,
  settings,
}: {
  id: number;
  settings: string;
}): Promise<UserSettingsResponse> {
  return $api.put(`admin/user-managment/users/${id}/settings/interface/base`, {
    settings,
  });
}

export async function getUserGameSettings(
  id: number,
  signal: AbortSignal,
): Promise<UserSettingsResponse> {
  return $api.get(`admin/user-managment/users/${id}/settings/interface/base`, {
    signal,
  });
}
