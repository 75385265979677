import { useEffect, useState } from 'react';
import { User } from 'types/user';
import UserGameSettingsForm from './gameLaunchSettingsForm';
import { getUserGameSettings } from 'api/AdministrationUser';
import { showSnackbar } from 'store/slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import { UserGameSettings } from 'types/adminService';
import { Preloader } from 'components/Preloader/Preloader';
import axios from 'axios';

interface GameLaunchSettingsContainerProps {
  user: User;
  isOpenUserGameSettingsForm: boolean;
  setIsOpenUserGameSettingsForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserGameSettingsContainer: React.FC<GameLaunchSettingsContainerProps> = ({
  user,
  isOpenUserGameSettingsForm,
  setIsOpenUserGameSettingsForm,
}) => {
  const [userGameSettings, setUserGameSettings] = useState<UserGameSettings | null>(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      try {
        const { data } = await getUserGameSettings(user.id, signal);
        let parsedSettings = {};

        if (data.settings) {
          try {
            parsedSettings = JSON.parse(data.settings);
          } catch (parseError) {
            dispatch(showSnackbar({ message: 'Invalid game settings format', severity: 'error' }));
            console.error('Failed to parse Game Settings:', parseError);
            return;
          }
        }

        setUserGameSettings(parsedSettings);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        const errorMessage = (error as Error).message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
        console.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [dispatch, user.id]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <UserGameSettingsForm
          user={user}
          userGameSettings={userGameSettings || {}}
          isOpenGameLaunchSettings={isOpenUserGameSettingsForm}
          setIsOpenUserGameSettingsForm={setIsOpenUserGameSettingsForm}
        />
      )}
    </>
  );
};

export default UserGameSettingsContainer;
