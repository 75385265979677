import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import { useAppDispatch } from 'hooks/useRedux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Tag } from 'types/adminService';
import { removeEmptyObjectValues } from 'utils/object';
import { changeTagInfo, createNewTag } from 'api/AdministrationTag';
import { fetchTags } from 'store/slices/tagsSlice';
import { showSnackbar } from 'store/slices/snackbarSlice';
import styles from './tagForm.module.scss';

interface TagFormProps {
  isFormOpen: boolean;
  handleClose: () => void;
  tag?: Tag;
}

interface SubmitFormData {
  title: string;
  description?: string;
  value?: string;
}

const TagForm: React.FC<TagFormProps> = ({ isFormOpen, handleClose, tag }) => {
  const dispatch = useAppDispatch();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<SubmitFormData>({
    defaultValues: {
      title: tag?.title,
      description: tag?.description || '',
      value: tag?.value || '',
    },
  });

  useEffect(() => {
    if (tag) {
      reset(tag);
    } else {
      reset({
        title: '',
        description: '',
        value: '',
      });
    }
  }, [reset, tag]);

  const onSubmit: SubmitHandler<SubmitFormData> = async (data) => {
    const tagWithoutEmptyProperties = removeEmptyObjectValues(data);
    try {
      if (tag) {
        await changeTagInfo(tag.id, tagWithoutEmptyProperties);
        handleSuccess('Tag changed successfully!');
      } else {
        await createNewTag(tagWithoutEmptyProperties);
        handleSuccess('Tag added successfully!');
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccess = (message: string): void => {
    dispatch(fetchTags());
    dispatch(showSnackbar({ message, severity: 'success' }));
    handleClose();
    reset();
  };

  const handleError = (error: any): void => {
    const errorMessage = (error as Error).message;
    dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    console.error(errorMessage);
  };

  return (
    <>
      <Dialog open={isFormOpen} onClose={handleClose}>
        <DialogTitle className={styles.tagDialogTitle}>{tag ? 'Edit Tag' : 'Add Tag'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.tagForm}>
              <div className={styles.fieldForm}>
                <TextField
                  label="Title"
                  variant="standard"
                  className={styles.tagFormInput}
                  placeholder="Title"
                  {...register('title', { required: 'Required field' })}
                  error={!!errors.title}
                  helperText={errors?.title ? errors.title.message : null}
                />
              </div>
              <div className={styles.fieldForm}>
                <TextField
                  label="Description"
                  variant="standard"
                  className={styles.tagFormInput}
                  placeholder="Description"
                  {...register('description')}
                />
              </div>
              <div className={styles.fieldForm}>
                <TextField
                  label="Value"
                  variant="standard"
                  className={styles.tagFormInput}
                  placeholder="Value"
                  {...register('value')}
                />
              </div>
            </div>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">{tag ? 'Apply' : 'Add'}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TagForm;
