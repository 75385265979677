import { ENVIRONMENT, PAGE, PERMISSION_TITLE } from 'consts/constants';
import { type BalanceMode, type Page } from 'types/common';
import { type Balance, type Permission, type PermissionTitle } from 'types/user';

export function extractUserBalance({
  userBalance,
  balanceMode,
}: {
  userBalance: Balance[];
  balanceMode: BalanceMode;
}): number | undefined {
  return userBalance.find(({ mode }) => mode === balanceMode)?.value;
}

const permissionsToEnvsMappingForMainPage: Partial<Record<PermissionTitle, string>> = {
  [PERMISSION_TITLE.DEV_GAME_ACCESS]: ENVIRONMENT.DEV,
  [PERMISSION_TITLE.STAGE_GAME_ACCESS]: ENVIRONMENT.STAGE,
  [PERMISSION_TITLE.CERT_GAME_ACCESS]: ENVIRONMENT.CERT,
};

const permissionsToEnvsMappingForOtherPages: Partial<Record<PermissionTitle, string>> = {
  [PERMISSION_TITLE.DEV_GAME_ACCESS]: ENVIRONMENT.DEV,
  [PERMISSION_TITLE.STAGE_GAME_ACCESS]: ENVIRONMENT.STAGE,
  [PERMISSION_TITLE.CERT_GAME_ACCESS]: ENVIRONMENT.CERT,
  [PERMISSION_TITLE.LIVE_GAME_ACCESS]: ENVIRONMENT.LIVE,
};

export function getAllowedEnvironments(permissions: Permission[], page: Page): string[] {
  const mapping =
    page === PAGE.MAIN
      ? permissionsToEnvsMappingForMainPage
      : permissionsToEnvsMappingForOtherPages;

  return permissions
    .map((permission) => mapping[permission.title])
    .filter((environment): environment is string => environment !== undefined);
}

export function getInitEnvironment(allowedEnvironments: string[]): string {
  if (!allowedEnvironments.length) {
    return '';
  }
  return allowedEnvironments[0];
}

export function userHasAccessToEnvironment(
  permissions: Permission[],
  environment: string,
): boolean {
  return permissions
    .map((permission) => permissionsToEnvsMappingForOtherPages[permission.title])
    .includes(environment);
}

export function hasAnyPermission(
  permissions: Permission[],
  requiredPermissionTitles: PermissionTitle[],
): boolean {
  return requiredPermissionTitles.some((requiredPermissionTitle) =>
    permissions.some((permission) => permission.title === requiredPermissionTitle),
  );
}
