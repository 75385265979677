import { useEffect, useState } from 'react';
import {
  changeStatusOfApplyingGlobalLanguage,
  setCurrencyLaunchGame,
  setLanguageLaunchGame,
} from 'store/slices/gameLaunchSettingsSlice';
import { SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { type Currency } from 'types/common';
import { Game } from './Game';
import {
  Game as GameInterface,
  LegacyBackendGameURLParams,
  RunGameParameters,
} from 'types/gameService';
import { getGameIconPath } from 'utils/gameStatusIconPath';
import { getLegacyGameURL, resetGameState } from 'api/Game';
import { DEFAULT_SELECTED_LICENSED_SITE, GAME_MODE, LANGUAGE } from 'consts/constants';
import { showSnackbar } from 'store/slices/snackbarSlice';

interface GameProps {
  game: GameInterface;
  runGame: (gameParams: RunGameParameters) => void;
}

export const GameContainer: React.FC<GameProps> = ({ game, runGame }) => {
  const {
    gameIconUrl,
    currency,
    languages,
    latestVersion,
    release,
    gameClass,
    gameStatusForIntegrationBackend,
    gameUID,
  } = game;

  const [selectedCurrency, setSelectedCurrency] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [imagePath, setImagePath] = useState<string | undefined>();

  const dispatch = useAppDispatch();

  const { isNeedToApplyGlobalLanguage } = useAppSelector((state) => state.gameLaunchSettings);
  const gameLaunchSettings = useAppSelector((state) =>
    state.gameLaunchSettings.gameLaunchSettings.find(
      (gameSettings) => gameSettings.gameClass === gameClass,
    ),
  );
  const {
    integrationBackend,
    selectedLicensedSite,
    environment,
    isSimpleLaunchGameMode,
    globalLanguage,
    isBaseInterface,
    service,
  } = useAppSelector((state) => state.stateUI);

  useEffect(() => {
    if (latestVersion) {
      setVersion(latestVersion);
    } else if (release?.tag) {
      setVersion(release.tag);
    }
  }, [latestVersion, release]);

  useEffect(() => {
    if (gameLaunchSettings?.selectedCurrency) {
      setSelectedCurrency(gameLaunchSettings.selectedCurrency);
    } else if (currency && currency.length > 0) {
      setSelectedCurrency(currency[0]);
    }
  }, [gameLaunchSettings, currency]);

  useEffect(() => {
    if (!isNeedToApplyGlobalLanguage) {
      return;
    }

    if (languages?.includes(globalLanguage)) {
      setSelectedLanguage(globalLanguage);
      dispatch(setLanguageLaunchGame({ gameClass, selectedLanguage: globalLanguage }));
      dispatch(changeStatusOfApplyingGlobalLanguage(false));
    }
  }, [dispatch, gameClass, globalLanguage, isNeedToApplyGlobalLanguage, languages]);

  useEffect(() => {
    if (gameLaunchSettings?.selectedLanguage) {
      setSelectedLanguage(gameLaunchSettings.selectedLanguage);
    } else if (languages?.includes(LANGUAGE.EN)) {
      setSelectedLanguage(LANGUAGE.EN);
    } else if (languages && languages.length > 0) {
      setSelectedLanguage(languages[0]);
    }
  }, [gameLaunchSettings, languages]);

  useEffect(() => {
    const imagePath =
      gameStatusForIntegrationBackend[integrationBackend][selectedLicensedSite] === 'OK'
        ? gameIconUrl
        : getGameIconPath(
            gameStatusForIntegrationBackend[integrationBackend][selectedLicensedSite],
          );
    setImagePath(imagePath);
  }, [gameIconUrl, gameStatusForIntegrationBackend, integrationBackend, selectedLicensedSite]);

  const handleChangeLanguage = (event: SelectChangeEvent<string>) => {
    dispatch(setLanguageLaunchGame({ gameClass, selectedLanguage: event.target.value }));
    setSelectedLanguage(event.target.value);
  };

  const handleChangeCurrency = (event: SelectChangeEvent<string>) => {
    dispatch(
      setCurrencyLaunchGame({ gameClass, selectedCurrency: event.target.value as Currency }),
    );
    setSelectedCurrency(event.target.value as Currency);
  };

  const handleSelectVersion = (version: string) => {
    setVersion(version);
    copyUrlToBuffer(version);
  };

  const copyUrlToBuffer = async (version: string) => {
    const gameUrl = await fetchGameUrl(version);
    if (!gameUrl) {
      console.error('Failed to fetch game URL');
      dispatch(showSnackbar({ message: 'Failed to fetch game URL', severity: 'error' }));
      return;
    }

    const croppedUrl = cropUrl(gameUrl);
    writeClipboardText(croppedUrl);
  };

  const fetchGameUrl = async (version: string): Promise<string | undefined> => {
    const urlParams: LegacyBackendGameURLParams = {
      gameUid: gameUID || '',
      mode: GAME_MODE.REAL,
      currency: selectedCurrency,
      language: selectedLanguage,
      version,
      gameBackend: service,
      env: environment,
      site: DEFAULT_SELECTED_LICENSED_SITE.NAME,
    };

    try {
      const { data } = await getLegacyGameURL(urlParams);
      return data.url;
    } catch (error) {
      console.error(`Error fetching game URL: ${(error as Error).message}`);
    }
  };

  const cropUrl = (url: string) => {
    // Trim the URL to retain only the base game link, excluding any parameters after "html",
    // as these are unnecessary in this case.
    const htmlIndex = url.indexOf('html');
    return htmlIndex !== -1 ? url.slice(0, htmlIndex + 4) : url;
  };

  const writeClipboardText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(showSnackbar({ message: 'Game URL copied to clipboard', severity: 'success' }));
    } catch (error) {
      console.error(`Error copying URL: ${(error as Error).message}`);
      dispatch(showSnackbar({ message: 'Failed to copy URL to clipboard', severity: 'error' }));
    }
  };

  const handleResetGameState = async (gameMode: string) => {
    if (!gameUID) {
      console.error('Game state reset failed: Missing gameUID.');
      return;
    }

    try {
      await resetGameState(
        integrationBackend,
        gameUID,
        environment,
        gameMode,
        selectedLicensedSite,
      );
    } catch (error) {
      console.error(`Failed to reset game state: ${(error as Error).message}`);
    }
  };

  return (
    <>
      {imagePath ? (
        <Game
          game={game}
          runGame={runGame}
          service={service}
          version={version}
          handleSelectVersion={handleSelectVersion}
          selectedLanguage={selectedLanguage}
          handleChangeLanguage={handleChangeLanguage}
          selectedCurrency={selectedCurrency}
          handleChangeCurrency={handleChangeCurrency}
          imagePath={imagePath}
          handleResetGameState={handleResetGameState}
          isSimpleLaunchGameMode={isSimpleLaunchGameMode}
          isBaseInterface={isBaseInterface}
        ></Game>
      ) : null}
    </>
  );
};
