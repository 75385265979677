export const ROLE = {
  ADMIN: 'admin',
  DEVELOPER: 'developer',
  CERTIFICATOR: 'certificator',
};

export const ENVIRONMENT = {
  DEV: 'DEV',
  STAGE: 'STAGE',
  CERT: 'CERT',
  LIVE: 'LIVE',
};

export const ENVIRONMENT_WITHOUT_LIVE = Object.fromEntries(
  Object.entries(ENVIRONMENT).filter(([key]) => key !== 'LIVE'),
);

export const LANGUAGE = {
  EN: 'EN',
  IT: 'IT',
};

export const SERVICE = {
  DMS: 'DMS',
  LCS: 'LCS',
};

export const INTEGRATION = {
  SEAMLESS: 'SEAMLESS',
  WALLETTRANSFER: 'WALLETTRANSFER',
};

export const EXIT_URL = 'https:wallet.octaviangames.com';

export const ROUTE = {
  MAIN: '/',
  LOGIN: '/login',
  ADMIN: '/admin',
  ENVIRONMENTS: '/environments',
  LIVE_ENV_MONITORING: '/liveEnvMonitoring',
};

export const GAME_MODE = {
  DEMO: 'DEMO',
  REAL: 'REAL',
  FUN: 'FUN',
} as const;

export const DEFAULT_SELECTED_LICENSED_SITE = {
  NAME: 'LIGHT_WALLET_2_SITE',
  LICENSE_DESCRIPTION: 'OGS light wallet v2',
};

export const PERMISSION_TITLE = {
  DEV_GAME_ACCESS: 'dev_games_access',
  STAGE_GAME_ACCESS: 'stage_games_access',
  CERT_GAME_ACCESS: 'cert_games_access',
  LIVE_GAME_ACCESS: 'live_games_access',
  ENVS_PAGE_ACCESS: 'envs_page_access',
  USER_MANAGEMENT: 'user_managment',
  MISSETTLED_TRANSACTION: 'missettled_transaction_test',
  INTERFACE_BASE: 'interface_base',
} as const;

export const INTEGRATION_BACKEND = {
  LEGACY: 'LEGACY',
  OSI: 'OSI',
} as const;

export const CURRENCY = {
  EUR: 'EUR',
  RUB: 'RUB',
  FUN: 'FUN',
} as const;

export const CATEGORY_SORT = {
  DEPLOY_DATE: 'Deploy date',
  GAME_CLASS: 'Game class',
} as const;

export const BALANCE_MODE = {
  REAL: 'REAL',
  FUN: 'FUN',
} as const;

export const USER_FORM_ACTION = {
  ADD: 'add',
  EDIT: 'edit',
} as const;

export const PAGE = {
  MAIN: 'main',
  ADMIN: 'admin',
  LOGIN: 'login',
  ENVIRONMENTS: 'environments',
  LIVE_ENV_MONITORING: 'liveEnvMonitoring',
} as const;

export const GAME_SORT_ORDER = {
  NEWEST_FIRST: 'NewestFirst',
  OLDEST_FIRST: 'OldestFirst',
  ASCENDING: 'asc',
  DESCENDING: 'desc',
} as const;

export const TOKEN_STORAGE_KEY = 'token';
