import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { getGamesInfo } from 'api/AdministrationGame';
import { GameInfo } from 'types/adminService';
import EditGamesMetadata from './EditGamesMetadata';
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import axios from 'axios';

const EditGamesMetadataContainer = () => {
  const [gamesInfo, setGamesInfo] = useState<GameInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchGameMetadata = async () => {
      try {
        const response = await getGamesInfo(signal);
        setGamesInfo(response.data.games);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        console.error((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGameMetadata();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <EditGamesMetadata gamesInfo={gamesInfo} isLoading={isLoading} />
    </ErrorBoundary>
  );
};

export default EditGamesMetadataContainer;
