import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { PlayCircleFilledOutlined } from '@mui/icons-material';
import { GAME_MODE, SERVICE } from 'consts/constants';
import { useAppSelector } from 'hooks/useRedux';
import { Game as GameInterface, RunGameParameters } from 'types/gameService';
import ResetGameContainer from './ResetGame/ResetGameContainer';
import styles from './gameControlPanel.module.scss';
import { mapAndSortVersions } from 'utils/extractAndSortGameVersions';

interface GameProps {
  game: GameInterface;
  runGame: (gameParams: RunGameParameters) => void;
  service: string;
  selectedLanguage: string;
  handleChangeLanguage: (event: SelectChangeEvent<string>) => void;
  selectedCurrency: string;
  handleChangeCurrency: (event: SelectChangeEvent<string>) => void;
  version: string;
  handleSelectVersion: (version: string) => void;
  imagePath: string | undefined;
  handleResetGameState: (gameMode: string) => Promise<void>;
}

const GameControlPanel: React.FC<GameProps> = (props) => {
  const {
    game,
    runGame,
    service,
    selectedLanguage,
    handleChangeLanguage,
    selectedCurrency,
    handleChangeCurrency,
    version,
    handleSelectVersion,
    handleResetGameState,
  } = props;

  const {
    gameName: title,
    gameStatusForIntegrationBackend,
    gameClass,
    currency,
    languages,
    gameUID: gameUid,
    versions,
    release,
  } = game;

  const { integrationBackend, selectedLicensedSite } = useAppSelector((state) => state.stateUI);

  const isDmsService = service === SERVICE.DMS;

  const gameVerionsArray = versions ? mapAndSortVersions(versions) : [];

  const isGameAvailable =
    gameStatusForIntegrationBackend[integrationBackend][selectedLicensedSite] === 'OK';

  return (
    <div className={styles.controlPanel}>
      <div className={styles.info}>
        <CardContent className={styles.gameNameBlock}>
          <Typography className={styles.title} gutterBottom variant="h5" component="div">
            {title}
          </Typography>
        </CardContent>
        <div className={styles.gameClass}>Class: {gameClass}</div>
      </div>

      <div className={styles.selectGroup}>
        <FormControl variant="outlined" size="small" className={styles.formControlAndTextField}>
          <InputLabel htmlFor="language-label">Language</InputLabel>
          <Select
            inputProps={{ id: 'language-label' }}
            label="Game language"
            value={selectedLanguage}
            onChange={(event) => handleChangeLanguage(event)}
            MenuProps={{ disableScrollLock: true }}
          >
            {languages &&
              languages.map((language, index) => (
                <MenuItem value={language} key={index}>
                  {language}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" className={styles.formControlAndTextField}>
          <InputLabel htmlFor="currency-label">Currency</InputLabel>
          <Select
            inputProps={{ id: 'currency-label' }}
            label="Currency"
            value={selectedCurrency}
            onChange={(event) => handleChangeCurrency(event)}
            MenuProps={{ disableScrollLock: true }}
          >
            {currency &&
              currency.map((currency, index) => (
                <MenuItem value={currency} key={index}>
                  {currency}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {versions ? (
          <FormControl variant="outlined" size="small" className={styles.formControlAndTextField}>
            <InputLabel htmlFor={`${gameClass} ${version}`}>Game version</InputLabel>
            <Select
              inputProps={{ id: `${gameClass} ${version}` }}
              value={version}
              label="Game version"
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 200,
                    overflow: 'auto',
                    overflowX: 'hidden',
                    '& .MuiMenuItem-root': {
                      display: 'flex',
                      gap: '5px',
                    },
                    '& .MuiMenuItem-root .name': {
                      fontWeight: 'bold',
                    },
                    '& .MuiMenuItem-root .info': {
                      marginLeft: 'auto',
                      display: 'flex',
                      gap: '10px',
                      color: 'text.secondary',
                    },
                  },
                },
                disableScrollLock: true,
              }}
              renderValue={(selected) => {
                const selectedVersion = gameVerionsArray.find(
                  (version) => version.name === selected,
                );
                return selectedVersion ? selectedVersion.name : selected;
              }}
            >
              {gameVerionsArray.map(({ name, size, modified }) => (
                <MenuItem key={name} value={name} onClick={() => handleSelectVersion(name)}>
                  <span className="name">{name}</span>
                  <span className="info">
                    <span className="modified">{modified}</span>
                    <span className="size">{size}</span>
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : release ? (
          <TextField
            className={styles.formControlAndTextField}
            size="small"
            id="outlined-basic"
            label="Game version"
            variant="outlined"
            value={version}
          />
        ) : null}
      </div>

      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: '10px',
          mb: '15px',
          padding: 0,
          width: '95%',
        }}
      >
        <Button
          sx={{ width: '120px', height: '30px', padding: 0 }}
          variant="outlined"
          startIcon={<PlayCircleFilledOutlined />}
          onClick={() =>
            runGame({
              mode: GAME_MODE.DEMO,
              gameUid: gameUid || '',
              version,
              language: selectedLanguage,
              currency: selectedCurrency,
            })
          }
          disabled={!isGameAvailable}
        >
          {GAME_MODE.DEMO}
        </Button>
        <Button
          sx={{ width: '120px', height: '30px', padding: 0 }}
          variant="outlined"
          startIcon={<PlayCircleFilledOutlined />}
          onClick={() =>
            runGame({
              mode: GAME_MODE.REAL,
              gameUid: gameUid || '',
              version,
              language: selectedLanguage,
              currency: selectedCurrency,
            })
          }
          disabled={!isGameAvailable}
        >
          {GAME_MODE.REAL}
        </Button>
        <Button
          sx={{ width: '120px', height: '30px', padding: 0 }}
          variant="outlined"
          startIcon={<PlayCircleFilledOutlined />}
          onClick={() =>
            runGame({
              mode: GAME_MODE.FUN,
              gameUid: gameUid || '',
              version,
              language: selectedLanguage,
              currency: selectedCurrency,
            })
          }
          disabled={!isGameAvailable || isDmsService}
        >
          {GAME_MODE.FUN}
        </Button>
      </CardActions>
      {service === SERVICE.LCS && (
        <ResetGameContainer handleResetGameState={handleResetGameState} />
      )}
    </div>
  );
};

export default GameControlPanel;
