import { ConfiguredCurrency } from 'types/configuredCurrency';
import { EnvLanguage } from 'types/gameService';
import { LicensedSite } from 'types/backendService';

export const createCurrencyOptions = (currencies: ConfiguredCurrency[]) =>
  currencies.map(({ id }) => ({
    value: id,
    label: id,
  }));

export const createLanguageOptions = (languages: EnvLanguage[]) =>
  languages.map(({ id }) => ({
    value: id,
    label: id,
  }));

export const createLanguageOptionsWithDescription = (languages: EnvLanguage[]) =>
  languages.map(({ id, description }) => ({
    value: id,
    label: description,
  }));

export const createLicenseOptions = (sites: LicensedSite[]) =>
  sites.map(({ name, licenseDescription }) => ({
    value: name,
    label: licenseDescription,
  }));
