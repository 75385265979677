import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { Preloader } from 'components/Preloader/Preloader';
import { ErrorBoundary } from 'react-error-boundary';
import GameTagsTable from './gameTagsTable/GameTagsTable';
import { GameTag } from 'types/adminService';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { useEffect } from 'react';
import { fetchGamesInfo } from 'store/slices/allGamesInfoSlice';
import { createNewTag } from 'api/AdministrationTag';
import { fetchTags } from 'store/slices/tagsSlice';
import { addTagToGame, deleteGameTag } from 'api/AdministrationGameTag';
import { getTagIdByTitle } from 'utils/tag';

const EditGameTagsContainer = () => {
  const dispatch = useAppDispatch();
  const { gamesInfo, isLoading: isLoadingGamesInfo } = useAppSelector(
    (state) => state.allGamesInfo,
  );
  const { tags, isLoading: isLoadingTags } = useAppSelector((state) => state.tags);

  useEffect(() => {
    dispatch(fetchGamesInfo());
    dispatch(fetchTags());
  }, [dispatch]);

  const handleAddTag = async (newTag: GameTag) => {
    const tagId = getTagIdByTitle(tags, newTag.title);

    if (tagId) {
      await addTagToGame(newTag.gameId, tagId);
    } else {
      await createNewTag(newTag);
    }

    dispatch(fetchGamesInfo());
    dispatch(fetchTags());
  };

  const handleDeleteTag = async (gameId: number, tagId: number) => {
    await deleteGameTag(gameId, tagId);
    dispatch(fetchGamesInfo());
    dispatch(fetchTags());
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {isLoadingGamesInfo && isLoadingTags ? (
        <Preloader />
      ) : (
        <GameTagsTable
          gamesInfo={gamesInfo}
          tags={tags}
          handleAddTag={handleAddTag}
          handleDeleteTag={handleDeleteTag}
        />
      )}
    </ErrorBoundary>
  );
};

export default EditGameTagsContainer;
