import { Dispatch, SetStateAction } from 'react';
import CreditContainer from './Credit/CreditContainer';
import styles from './userInfo.module.scss';
import classNames from 'classnames';

interface UserInfoProps {
  userName: string;
  isDisplayCredit: boolean;
  setIsOpenUserForm: Dispatch<SetStateAction<boolean>>;
  userHasManagementPermission: boolean;
}

const UserInfo = ({
  userName,
  isDisplayCredit,
  setIsOpenUserForm,
  userHasManagementPermission,
}: UserInfoProps) => {
  const userInfoClass = classNames(styles.userInfo, {
    [styles.demoUserInterface]: !isDisplayCredit,
  });

  return (
    <div className={userInfoClass}>
      {userHasManagementPermission ? (
        <div className={styles.userName} onClick={() => setIsOpenUserForm(true)}>
          {userName}
        </div>
      ) : (
        <div className={styles.userName_withoutUserManagementPermission}>{userName}</div>
      )}
      {isDisplayCredit && <CreditContainer />}
    </div>
  );
};

export default UserInfo;
