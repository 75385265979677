import {
  AllConfiguredRolesResponseData,
  CreateNewRoleAPIParams,
  CreateRoleResponseData,
  RoleAPIParams,
} from 'types/adminService';
import $api from 'utils/http';

export async function getUserRoles(): Promise<AllConfiguredRolesResponseData> {
  return $api.get('admin/role-managment/roles');
}

export async function createNewRole(data: CreateNewRoleAPIParams): Promise<CreateRoleResponseData> {
  return $api.post('admin/role-managment/roles', data);
}

export async function changeRole(id: number, data: RoleAPIParams) {
  return $api.put(`admin/role-managment/roles/${id}`, data);
}

export async function deleteRole(id: number) {
  return $api.delete(`admin/role-managment/roles/${id}`);
}
