import React, { useEffect } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  SelectChangeEvent,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import styles from './filterPanel.module.scss';
import { type CategorySort, type GameSortOrder, type IntegrationBackend } from 'types/common';
import { CATEGORY_SORT, GAME_SORT_ORDER, INTEGRATION_BACKEND, SERVICE } from 'consts/constants';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { EnvLanguage } from 'types/gameService';
import { LicensedSite } from 'types/backendService';
import OnlyWorkingGamesSwitch from 'components/OnlyWorkingGamesSwitch/OnlyWorkingGamesSwitch';
import {
  CATEGORY_SORT_SELECT_OPTIONS,
  INTEGRATION_BACKEND_SELECT_OPTIONS,
  SERVICE_SELECT_OPTIONS,
} from 'consts/selectOptions';
import { createLanguageOptionsWithDescription, createLicenseOptions } from 'utils/selectOptions';

interface FilterPanelProps {
  integrationBackend: IntegrationBackend;
  handleIntegrationBackground: (event: SelectChangeEvent<string>) => void;
  isOnlyWorkingGames: boolean;
  handleStatusGame: () => void;
  isOnlyClassesA: boolean;
  handleTypeClass: () => void;
  categorySort: CategorySort;
  handleCategorySortChange: (event: SelectChangeEvent<string>) => void;
  sortOrder: GameSortOrder;
  handleSortOrderChange: (arg: GameSortOrder) => void;
  service: string;
  handleChangeService: (event: SelectChangeEvent<string>) => void;
  environment: string;
  handleChangeEnvironment: (environment: string) => void;
  environments: string[];
  isSimpleLaunchGameMode: boolean;
  handleLaunchGameMode: () => void;
  globalLanguage: string;
  envLanguages: EnvLanguage[];
  handleGlobalLanguage: (event: SelectChangeEvent<string>) => void;
  selectedLicensedSite: string;
  licensedSites: LicensedSite[];
  handleLicense: (event: SelectChangeEvent<string>) => void;
}

interface SortingOption {
  value: GameSortOrder;
  label: string;
}

const deployDateOptions: SortingOption[] = [
  { value: GAME_SORT_ORDER.NEWEST_FIRST, label: 'Newest first' },
  { value: GAME_SORT_ORDER.OLDEST_FIRST, label: 'Oldest first' },
];

const gameClassOptions: SortingOption[] = [
  { value: GAME_SORT_ORDER.ASCENDING, label: 'A-Z' },
  { value: GAME_SORT_ORDER.DESCENDING, label: 'Z-A' },
];

const getOrderOptions = (categorySort: CategorySort): SortingOption[] => {
  if (categorySort === CATEGORY_SORT.DEPLOY_DATE) {
    return deployDateOptions;
  }
  return gameClassOptions;
};

const FilterPanel: React.FC<FilterPanelProps> = ({
  integrationBackend,
  handleIntegrationBackground,
  isOnlyWorkingGames,
  handleStatusGame,
  isOnlyClassesA,
  handleTypeClass,
  categorySort,
  handleCategorySortChange,
  sortOrder,
  handleSortOrderChange,
  service,
  handleChangeService,
  environment,
  handleChangeEnvironment,
  environments,
  isSimpleLaunchGameMode,
  handleLaunchGameMode,
  globalLanguage,
  envLanguages,
  handleGlobalLanguage,
  selectedLicensedSite,
  licensedSites,
  handleLicense,
}) => {
  const orderOptions = getOrderOptions(categorySort);
  const isValidSortOrder = orderOptions.some((option) => option.value === sortOrder);

  useEffect(() => {
    if (!isValidSortOrder && orderOptions.length > 0) {
      handleSortOrderChange(orderOptions[0].value);
    }
  }, [categorySort, isValidSortOrder, orderOptions, handleSortOrderChange]);

  return (
    <div className={styles.filterPanel}>
      <FormControl component="fieldset" className={styles.environmentControl}>
        <ToggleButtonGroup color="primary" aria-label="Environment selection" value={environment}>
          {environments.map((env) => {
            return (
              <ToggleButton value={env} key={env} onClick={() => handleChangeEnvironment(env)}>
                {env}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </FormControl>

      <FormGroup className={styles.switchers}>
        <OnlyWorkingGamesSwitch checked={isOnlyWorkingGames} onChange={handleStatusGame} />
        <FormControlLabel
          control={<Switch checked={isOnlyClassesA} onChange={handleTypeClass} />}
          label="Only classes <A>"
          labelPlacement="start"
        />
        <FormControlLabel
          control={<Switch checked={isSimpleLaunchGameMode} onChange={handleLaunchGameMode} />}
          label="Simple launch mode"
          labelPlacement="start"
        />
      </FormGroup>
      <div className={styles.selectGroup}>
        <CustomSelect
          label="Sort by"
          value={categorySort}
          options={CATEGORY_SORT_SELECT_OPTIONS}
          onChange={handleCategorySortChange}
        />
        <CustomSelect
          label="Order by"
          value={isValidSortOrder ? sortOrder : orderOptions[0]?.value}
          options={orderOptions}
          onChange={(value) => handleSortOrderChange(value.target.value as GameSortOrder)}
        />
        <CustomSelect
          label="Service"
          value={service}
          options={SERVICE_SELECT_OPTIONS}
          onChange={handleChangeService}
        />
        <CustomSelect
          label="Integration backend"
          value={service === SERVICE.DMS ? INTEGRATION_BACKEND.LEGACY : integrationBackend}
          options={INTEGRATION_BACKEND_SELECT_OPTIONS}
          onChange={handleIntegrationBackground}
          disabled={service === SERVICE.DMS}
        />
        <CustomSelect
          label="License"
          value={selectedLicensedSite}
          options={createLicenseOptions(licensedSites)}
          onChange={handleLicense}
          disabled={service === SERVICE.DMS}
        />
        <CustomSelect
          label="Language"
          value={globalLanguage}
          options={createLanguageOptionsWithDescription(envLanguages)}
          onChange={handleGlobalLanguage}
        />
      </div>
    </div>
  );
};

export default FilterPanel;
