import { ChangeTagAPIParams, CreateNewTagAPIParams, GetTagsResponseData } from 'types/adminService';
import $api from 'utils/http';

export async function getTags(): Promise<GetTagsResponseData> {
  return $api.get('admin/tag-managment/tags');
}

export async function createNewTag(data: CreateNewTagAPIParams) {
  return $api.post('admin/tag-managment/tags', data);
}

export async function deleteTag(id: number) {
  return $api.delete(`admin/tag-managment/tags/${id}`);
}

export async function changeTagInfo(id: number, data: ChangeTagAPIParams) {
  return $api.put(`admin/tag-managment/tags/${id}`, data);
}
