import axios from 'axios';
import { ROUTE } from '../../consts/constants';

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setAuthorizationHeader = (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const refreshAccessToken = async (originalRequest) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}authentication/refresh?refreshToken=${localStorage.getItem('tokenR')}`,
    );
    localStorage.setItem('token', response.data.accessToken);
    return $api.request(originalRequest);
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenR');
    window.location.href = ROUTE.LOGIN;
    throw error;
  }
};

$api.interceptors.request.use(setAuthorizationHeader);

$api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if access token has expired
    if (error.response?.status === 401 && originalRequest && !originalRequest._isRetry) {
      originalRequest._isRetry = true;
      return await refreshAccessToken(originalRequest);
    }

    throw error;
  },
);

export default $api;
