import UsersTableRow from './TableRow/UsersTableRow';
import {
  styled,
  tableCellClasses,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { User } from 'types/user';
import { CurrencyConfig, ConfiguredCurrency } from 'types/configuredCurrency';
import styles from './usersTable.module.scss';
import { CustomButton } from 'components/CustomButton';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function sortUsersByName(users: User[]): User[] {
  return users
    .slice()
    .sort((a, b) => a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase()));
}

interface UsersTableProps {
  users: User[];
  isAdmin: boolean;
  currentCurrency: ConfiguredCurrency | undefined;
  funCurrencyConfig: CurrencyConfig | undefined;
  handleOpenCreditForm: ({
    realBalance,
    funBalance,
    userId,
  }: {
    realBalance: number;
    funBalance: number;
    userId: number;
  }) => Promise<void>;
  openUserForm: () => void;
  changeUser: (nickname: string) => Promise<void>;
  changePassword: (id: number) => Promise<void>;
  handleClickRemoveUser: (user: User) => void;
  handleOpenGameLaunchSettings: (user: User) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  isAdmin,
  currentCurrency,
  funCurrencyConfig,
  handleOpenCreditForm,
  openUserForm,
  changeUser,
  changePassword,
  handleClickRemoveUser,
  handleOpenGameLaunchSettings,
}) => {
  const sortedUsers = sortUsersByName(users);

  return (
    <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className={styles.tableHead}>
          <TableRow>
            <StyledTableCell align="center">Nickname</StyledTableCell>
            <StyledTableCell align="center">First name</StyledTableCell>
            <StyledTableCell align="center">Role</StyledTableCell>
            <StyledTableCell align="center">Credit(real)</StyledTableCell>
            <StyledTableCell align="center">Credit(fun)</StyledTableCell>
            <StyledTableCell align="center">
              <CustomButton label="Add new user" onClick={openUserForm} />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers.map((user) => (
            <UsersTableRow
              key={user.id}
              user={user}
              currentCurrency={currentCurrency}
              funCurrencyConfig={funCurrencyConfig}
              handleOpenCreditForm={handleOpenCreditForm}
              changeUser={changeUser}
              changePassword={changePassword}
              handleClickRemoveUser={handleClickRemoveUser}
              handleOpenGameLaunchSettings={handleOpenGameLaunchSettings}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
