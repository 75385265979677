import {
  styled,
  tableCellClasses,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Tag } from 'types/adminService';
import TagsTableRow from './TableRow/TagsTableRow';
import styles from './tagsTable.module.scss';
import { CustomButton } from 'components/CustomButton';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface TagsTableProps {
  tags: Tag[];
  handleFormOpen: (roleId?: number) => void;
  handleRemoveTagDialogOpen: (roleId: number) => void;
}

const TagsTable: React.FC<TagsTableProps> = ({
  tags,
  handleFormOpen,
  handleRemoveTagDialogOpen,
}) => {
  return (
    <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className={styles.tableHead}>
          <TableRow>
            <StyledTableCell align="center">Id</StyledTableCell>
            <StyledTableCell align="center">Title</StyledTableCell>
            <StyledTableCell align="center">Description</StyledTableCell>
            <StyledTableCell align="center">Value</StyledTableCell>
            <StyledTableCell align="center">
              <CustomButton onClick={handleFormOpen} label="Add new tag" />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tags.map((tag) => (
            <TagsTableRow
              key={tag.id}
              tag={tag}
              handleFormOpen={handleFormOpen.bind(undefined, tag.id)}
              removeTag={handleRemoveTagDialogOpen.bind(undefined, tag.id)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TagsTable;
