import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PermissionTitle } from 'types/user';
import { ROUTE } from 'consts/constants';
import { useHasPermission } from 'hooks/useHasPermission';

interface Props {
  requiredPermissions: PermissionTitle[];
}

export const RequireAuth: React.FC<Props> = ({ requiredPermissions }) => {
  const location = useLocation();

  return useHasPermission(requiredPermissions) ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTE.LOGIN} state={{ from: location }} />
  );
};
