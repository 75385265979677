import { GetTagsResponseData } from 'types/adminService';
import $api from 'utils/http';

export async function addTagToGame(gameId: number, tagId: number): Promise<GetTagsResponseData> {
  return $api.put(`admin/game-tag-managment/games/${gameId}/tags/${tagId}`);
}

export async function deleteGameTag(gameId: number, tagId: number): Promise<GetTagsResponseData> {
  return $api.delete(`admin/game-tag-managment/games/${gameId}/tags/${tagId}`);
}
