import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface OnlyWorkingGamesSwitchProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const OnlyWorkingGamesSwitch: React.FC<OnlyWorkingGamesSwitchProps> = ({
  checked,
  onChange,
  disabled = false,
}) => (
  <FormControlLabel
    control={<Switch checked={checked} onChange={onChange} />}
    label="Only working games"
    labelPlacement="start"
    disabled={disabled}
  />
);

export default OnlyWorkingGamesSwitch;
