import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';
import styles from './customSelect.module.scss';

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  label: string;
  value: string;
  options: SelectOption[];
  onChange: (event: SelectChangeEvent<string>) => void;
  disabled?: boolean;
  className?: string;
}

const CustomSelect: React.FC<SelectProps> = ({
  label,
  value,
  options,
  onChange,
  disabled,
  className,
}) => {
  const inputId = `${label.toLowerCase()}-label`;

  return (
    <FormControl variant="standard" disabled={disabled}>
      <InputLabel htmlFor={inputId}>{label}</InputLabel>
      <Select
        className={clsx(styles.select, className && styles[className])}
        inputProps={{ id: inputId }}
        label={label}
        value={value}
        onChange={onChange}
        MenuProps={{ disableScrollLock: true }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
