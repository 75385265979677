import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { logout } from 'store/slices/userAuthSlice';
import Header from './Header';
import { PERMISSION_TITLE } from 'consts/constants';
import { useHasPermission } from 'hooks/useHasPermission';
import { clearResetStatus } from 'store/slices/configUISlice';

const HeaderContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector((state) => state.userAuth.isAuth);
  const currentPage = useAppSelector((state) => state.currentPage.currentPage);
  const { isBaseInterface } = useAppSelector((state) => state.stateUI);

  const isAdminPageAllowed = useHasPermission([PERMISSION_TITLE.USER_MANAGEMENT]);
  const isEnvsPageAllowed = useHasPermission([PERMISSION_TITLE.ENVS_PAGE_ACCESS]);

  const handleLogout = () => {
    dispatch(clearResetStatus());
    dispatch(logout());
  };

  return (
    <Header
      isAuth={isAuth}
      handleLogout={handleLogout}
      currentPage={currentPage}
      isBaseInterface={isBaseInterface}
      isAdminPageAllowed={isAdminPageAllowed}
      isEnvsPageAllowed={isEnvsPageAllowed}
    />
  );
};

export default HeaderContainer;
