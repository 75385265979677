import { SelectChangeEvent } from '@mui/material';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import styles from './environmentSelect.module.scss';

interface EnvironmentSelectProps {
  environment: string;
  handleChangeEnvironment: (event: SelectChangeEvent<string>) => void;
  environments: string[];
  disabled: boolean;
}

const EnvironmentSelect: React.FC<EnvironmentSelectProps> = ({
  environment,
  handleChangeEnvironment,
  environments,
  disabled,
}) => {
  return (
    <div className={styles.environmentSelectWrapper}>
      <CustomSelect
        label="Environment"
        value={environment}
        options={environments.map((envOption) => ({
          value: envOption,
          label: envOption,
        }))}
        onChange={handleChangeEnvironment}
        disabled={disabled}
      />
    </div>
  );
};

export default EnvironmentSelect;
