import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTags } from 'api/AdministrationTag';
import { Tag } from 'types/adminService';

interface tagsState {
  tags: Tag[];
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: tagsState = {
  tags: [],
  isLoading: false,
  error: null,
};

export const fetchTags = createAsyncThunk<Tag[], void, { rejectValue: string }>(
  'tags/fetchTags',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getTags();
      return response.data.tags;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tags = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default tagsSlice.reducer;
