import { GetTagsResponseData } from 'types/adminService';
import $api from 'utils/http';

export async function addTagToUser(userId: number, tagId: number): Promise<GetTagsResponseData> {
  return $api.put(`admin/user-tag-managment/users/${userId}/tags/${tagId}`);
}

export async function removeUserTag(userId: number, tagId: number): Promise<GetTagsResponseData> {
  return $api.delete(`admin/user-tag-managment/users/${userId}/tags/${tagId}`);
}
