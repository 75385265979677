import { useCallback, useEffect, useState } from 'react';
import { Game } from 'types/gameService';
import { getGames } from 'api/Game';
import { showSnackbar } from '../store/slices/snackbarSlice';
import { useAppDispatch } from './useRedux';
import axios from 'axios';

function useFetchGames(
  initialEnvironment: string,
): [boolean, Game[], (environment: string) => void] {
  const [environment, setEnvironment] = useState<string>(initialEnvironment);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [games, setGames] = useState<Game[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchGames = async () => {
      setIsLoading(true);
      try {
        const response = await getGames(environment, signal);
        const { games } = response.data;

        setGames(games);
        if (games.length === 0) {
          dispatch(
            showSnackbar({
              message: 'There are no games available at the moment.',
              severity: 'warning',
            }),
          );
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        const errorMessage = (error as Error).message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
        console.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGames();

    return () => {
      controller.abort();
    };
  }, [dispatch, environment]);

  const changeEnvironment = useCallback((newEnvironment: string) => {
    setEnvironment(newEnvironment);
  }, []);

  return [isLoading, games, changeEnvironment];
}

export default useFetchGames;
