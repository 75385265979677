import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from 'components/hoc/RequireAuth';
import { MainPage } from 'components/pages/mainPage/MainPage';
import AdminPageContainer from 'components/pages/adminPage';
import { Preloader } from 'components/Preloader/Preloader';
import LoginPage from 'components/pages/loginPage/LoginPage/LoginPage';
import { MainLayout } from 'components/mainLayout/MainLayout';
import EnvironmentPage from 'components/pages/environmentPage/EnvironmentPage';
import { PERMISSION_TITLE, ROUTE } from 'consts/constants';
import LiveEnvMonitoringPage from 'components/pages/liveEnvMonitoringPage/LiveEnvMonitoringPage';
import { useAuth } from './hooks/useAuth';

function App() {
  const { isLoading, token } = useAuth();

  if (token && isLoading) {
    return <Preloader />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="login" element={<LoginPage />} />

          <Route
            element={
              <RequireAuth
                requiredPermissions={[
                  PERMISSION_TITLE.DEV_GAME_ACCESS,
                  PERMISSION_TITLE.STAGE_GAME_ACCESS,
                  PERMISSION_TITLE.CERT_GAME_ACCESS,
                ]}
              />
            }
          >
            <Route index element={<MainPage />} />
          </Route>

          <Route element={<RequireAuth requiredPermissions={[PERMISSION_TITLE.USER_MANAGEMENT]} />}>
            <Route path="admin" element={<AdminPageContainer />} />
          </Route>

          <Route
            element={<RequireAuth requiredPermissions={[PERMISSION_TITLE.ENVS_PAGE_ACCESS]} />}
          >
            <Route path="environments" element={<EnvironmentPage />} />
            <Route path="liveEnvMonitoring" element={<LiveEnvMonitoringPage />} />
          </Route>

          <Route path="*" element={<Navigate to={ROUTE.MAIN} replace />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
