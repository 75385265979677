import React, { FC, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { Preloader } from 'components/Preloader/Preloader';
import OnlyWorkingGamesSwitch from 'components/OnlyWorkingGamesSwitch/OnlyWorkingGamesSwitch';
import { ENVIRONMENT, PAGE } from 'consts/constants';
import useFetchGames from 'hooks/useFetchGames';
import LiveEnvMonitoringTable from './LiveEnvMonitoringTable/LiveEnvMonitoringTable';
import {
  extractGameInfo,
  extractGameTimestampAndVersion,
  filterWorkingGames,
  gameVersionsEqual,
} from 'utils/game';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setCurrentPage } from 'store/slices/currentPageSlice';
import styles from './liveEnvMonitoringPage.module.scss';
import { toggleWorkingGamesStateForPage } from 'store/slices/configUISlice';

const LiveEnvMonitoringPage: FC = () => {
  const dispatch = useAppDispatch();
  const [isLoadingLiveGames, liveGames] = useFetchGames(ENVIRONMENT.LIVE);
  const [isLoadingDevGames, devGames] = useFetchGames(ENVIRONMENT.DEV);
  const {
    workingGamesSwitcherStates: { liveEnvMonitoring: isOnlyWorkingGames },
  } = useAppSelector((state) => state.stateUI);
  const isLoading = isLoadingLiveGames && isLoadingDevGames;

  useEffect(() => {
    dispatch(setCurrentPage(PAGE.LIVE_ENV_MONITORING));
  }, [dispatch]);

  const handleStatusGame = (): void => {
    dispatch(toggleWorkingGamesStateForPage(PAGE.LIVE_ENV_MONITORING));
  };

  const liveGamesWithEnvReleaseInfo = useMemo(() => {
    const gamesToDisplay = isOnlyWorkingGames ? filterWorkingGames(liveGames) : liveGames;

    return gamesToDisplay.map((game) => {
      const gameDevInfo = extractGameInfo(devGames, game.gameClass);
      const gameLiveReleaseInfo = extractGameTimestampAndVersion(game);
      if (gameDevInfo) {
        const gameDevReleaseInfo = extractGameTimestampAndVersion(gameDevInfo);
        const liveAndDevHasEqualVersion = gameVersionsEqual(game, gameDevInfo);
        return { ...game, gameDevReleaseInfo, gameLiveReleaseInfo, liveAndDevHasEqualVersion };
      }
      return { ...game, gameLiveReleaseInfo };
    });
  }, [isOnlyWorkingGames, liveGames, devGames]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={styles.filterBar}>
        <OnlyWorkingGamesSwitch
          checked={isOnlyWorkingGames}
          onChange={handleStatusGame}
          disabled={isLoading}
        />
      </div>
      {isLoading ? <Preloader /> : <LiveEnvMonitoringTable games={liveGamesWithEnvReleaseInfo} />}
    </ErrorBoundary>
  );
};

export default LiveEnvMonitoringPage;
