import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type Currency } from 'types/common';

interface LanguagePayloadAction {
  gameClass: string;
  selectedLanguage: string;
}

interface CurrencyPayloadAction {
  gameClass: string;
  selectedCurrency: Currency;
}

interface Settings {
  gameClass: string;
  selectedCurrency?: Currency | undefined;
  selectedLanguage?: string | undefined;
}

interface GameLaunchSettings {
  isNeedToApplyGlobalLanguage: boolean;
  gameLaunchSettings: Settings[];
}

const initialState: GameLaunchSettings = {
  isNeedToApplyGlobalLanguage: false,
  gameLaunchSettings: [],
};

const gameLaunchSettingsSlice = createSlice({
  name: 'gameLaunchSettings',
  initialState,
  reducers: {
    setCurrencyLaunchGame: (state, action: PayloadAction<CurrencyPayloadAction>) => {
      const currentCurrency = state.gameLaunchSettings.find(
        (gameSettings) => gameSettings.gameClass === action.payload.gameClass,
      );
      if (currentCurrency) {
        currentCurrency.selectedCurrency = action.payload.selectedCurrency;
      } else {
        state.gameLaunchSettings.push({
          gameClass: action.payload.gameClass,
          selectedCurrency: action.payload.selectedCurrency,
        });
      }
    },
    setLanguageLaunchGame: (state, action: PayloadAction<LanguagePayloadAction>) => {
      const currentLanguage = state.gameLaunchSettings.find(
        (gameSettings) => gameSettings.gameClass === action.payload.gameClass,
      );
      if (currentLanguage) {
        currentLanguage.selectedLanguage = action.payload.selectedLanguage;
      } else {
        state.gameLaunchSettings.push({
          gameClass: action.payload.gameClass,
          selectedLanguage: action.payload.selectedLanguage,
        });
      }
    },
    changeStatusOfApplyingGlobalLanguage: (state, action: PayloadAction<boolean>) => {
      state.isNeedToApplyGlobalLanguage = action.payload;
    },
  },
});

export const {
  setCurrencyLaunchGame,
  setLanguageLaunchGame,
  changeStatusOfApplyingGlobalLanguage,
} = gameLaunchSettingsSlice.actions;

export default gameLaunchSettingsSlice.reducer;
