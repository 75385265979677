import { styled, TableCell, tableCellClasses } from '@mui/material';
import { CurrencyConfig } from 'types/configuredCurrency';
import styles from './balanceTableCell.module.scss';
import { formatBalance } from 'utils/currency';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface BalanceTableCellProps {
  balance: number;
  currencyConfig: CurrencyConfig;
  handleClickOpenCreditForm: () => void;
}

const BalanceTableCell: React.FC<BalanceTableCellProps> = ({
  balance,
  currencyConfig,
  handleClickOpenCreditForm,
}) => {
  return (
    <StyledTableCell align="center">
      <button onClick={() => handleClickOpenCreditForm()} className={styles.creditButton}>
        {formatBalance(balance, currencyConfig)}
      </button>
    </StyledTableCell>
  );
};

export default BalanceTableCell;
