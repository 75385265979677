import $api from 'utils/http';
import { LoginResponseData } from 'types/authService';
import { hashString } from '../utils/crypto';

export async function logIn(login: string, password: string): Promise<LoginResponseData> {
  const hashedPassword = hashString(password);

  return await $api.get('authentication/signin', {
    params: {
      nickname: login,
      password: hashedPassword,
    },
  });
}

export async function auth() {
  return $api.get(
    `${process.env.REACT_APP_API_URL}authentication/refresh?refreshToken=${localStorage.getItem('tokenR')}`,
  );
}
