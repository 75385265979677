import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { fetchRoles } from 'store/slices/rolesSlice';
import { hideSnackbar, showSnackbar } from 'store/slices/snackbarSlice';
import { setCurrentPage } from 'store/slices/currentPageSlice';
import { updateInfo } from 'api/AdministrationGame';
import AdminPage from './AdminPage';
import { PAGE } from 'consts/constants';

const AdminPageContainer = () => {
  const dispatch = useAppDispatch();
  const { open, message, severity } = useAppSelector((state) => state.snackbar);

  useEffect(() => {
    dispatch(setCurrentPage(PAGE.ADMIN));
    dispatch(fetchRoles());
  }, [dispatch]);

  const handleAlertClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') {
      dispatch(hideSnackbar());
    }
  };

  const updateGamesInfo = async () => {
    try {
      await updateInfo();
      dispatch(
        showSnackbar({
          message: 'Request to update games info sent successfully',
          severity: 'success',
        }),
      );
    } catch (error) {
      const errorMessage = (error as Error).message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      console.error(errorMessage);
    }
  };

  return (
    <AdminPage
      handleAlertClose={handleAlertClose}
      updateGamesInfo={updateGamesInfo}
      snackbar={{ open, message, severity }}
    />
  );
};

export default AdminPageContainer;
