import { useAppDispatch, useAppSelector } from './useRedux';
import { useEffect, useState } from 'react';
import { PAGE, PERMISSION_TITLE, TOKEN_STORAGE_KEY } from 'consts/constants';
import { resetToDefaultState, setEnvironment, setInterface } from 'store/slices/configUISlice';
import { fetchAndApplyUserSettings, setAllowedEnvironments } from 'store/slices/userDataSlice';
import { updateUserAuth } from 'store/slices/userAuthSlice';
import { useHasPermission } from './useHasPermission';
import { usePageAllowedEnvironments } from './usePageAllowedEnvironments';
import { useHasAccessToEnvironment } from './useHasAccessToEnvironment';

export function useAuth() {
  const dispatch = useAppDispatch();
  const { info: user, isNewUser } = useAppSelector((state) => state.user);
  const { isAuth, isLoading } = useAppSelector((state) => state.userAuth);
  const { environment, hasReset } = useAppSelector((state) => state.stateUI);

  const hasBaseInterfacePermission = useHasPermission([PERMISSION_TITLE.INTERFACE_BASE]);
  const { allowedEnvironments, initEnvironment } = usePageAllowedEnvironments(PAGE.MAIN);
  const userHasAccessToEnvironment = useHasAccessToEnvironment(environment);

  const [token] = useState(() => {
    return localStorage.getItem(TOKEN_STORAGE_KEY);
  });

  useEffect(() => {
    if (!userHasAccessToEnvironment) {
      dispatch(setEnvironment(initEnvironment));
    }

    dispatch(setAllowedEnvironments(allowedEnvironments));
  }, [allowedEnvironments, dispatch, initEnvironment, userHasAccessToEnvironment]);

  useEffect(() => {
    if (!user || !isAuth) return;

    dispatch(setInterface(hasBaseInterfacePermission));

    if (isNewUser && !hasReset) {
      dispatch(resetToDefaultState());
    }

    if (!hasBaseInterfacePermission) {
      dispatch(fetchAndApplyUserSettings());
    }
  }, [dispatch, hasBaseInterfacePermission, hasReset, isAuth, isNewUser, user]);

  useEffect(() => {
    if (token) {
      dispatch(updateUserAuth());
    }
  }, [token, dispatch]);

  return { isLoading, token };
}
