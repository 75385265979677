import { FC, useEffect, useState } from 'react';
import MissettledTransactionFeature from './MissettledTransactionFeature';
import {
  getMissettledTransactionFeatureState,
  setMissettledTransactionFeatureState,
} from 'api/User';
import axios from 'axios';

const MissettledTransactionFeatureContainer: FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchMissettledTransactionFeatureState = async () => {
      try {
        const response = await getMissettledTransactionFeatureState(signal);
        setIsActive(response.data.missettledTransactionTestRequest);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        console.error((error as Error).message);
      }
    };

    fetchMissettledTransactionFeatureState();

    return () => {
      controller.abort();
    };
  }, []);

  const onToggle = async () => {
    const newState = !isActive;
    try {
      const response = await setMissettledTransactionFeatureState(newState);
      setIsActive(response.data.missettledTransactionTestRequest);
    } catch (error) {
      const errorMessage = (error as Error).message;
      console.error(errorMessage);
    }
  };

  return <MissettledTransactionFeature isActive={isActive} onToggle={onToggle} />;
};

export default MissettledTransactionFeatureContainer;
