import { Box, Tab, Tabs, Button, useTheme } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';
import CustomTabPanel from './CustomTabPanel/CustomTabPanel';
import { EditUsersContainer } from './editUsers/EditUserContainer/EditUsersContainer';
import EditRolesContainer from './editRoles/EditRolesContainer';
import EditGameTagsContainer from './editGameTags/EditGameTagsContainer';
import EditTagsContainer from './editTags/EditTagsContainer';
import EditUserTagsContainer from './editUserTags/EditUserTagsContainer';
import EditGamesMetadata from './editGamesMetadata/EditGamesMetadataContainer';
import { useState } from 'react';
import { SnackbarState } from 'store/slices/snackbarSlice';

interface AdminPageProps {
  handleAlertClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  updateGamesInfo: () => Promise<void>;
  snackbar: SnackbarState;
}

const adminPanelTabs = [
  { label: 'Edit users' },
  { label: 'Edit roles' },
  { label: 'Edit users tags' },
  { label: 'Edit games tags' },
  { label: 'Edit tags' },
  { label: 'Edit games metadata' },
];

const adminPanelComponents = [
  <EditUsersContainer />,
  <EditRolesContainer />,
  <EditUserTagsContainer />,
  <EditGameTagsContainer />,
  <EditTagsContainer />,
  <EditGamesMetadata />,
];

const AdminPage: React.FC<AdminPageProps> = ({ handleAlertClose, updateGamesInfo, snackbar }) => {
  const theme = useTheme();
  const { open, message, severity } = snackbar;
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'space-around',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              alignItems: 'center',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="admin panel tabs"
              textColor="inherit"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              indicatorColor="secondary"
            >
              {adminPanelTabs.map((tab, index) => (
                <Tab
                  label={tab.label}
                  aria-controls={`tabpanel-${index}`}
                  key={index}
                  id={`tab-${index}`}
                />
              ))}
            </Tabs>
            <Button
              variant="outlined"
              onClick={updateGamesInfo}
              sx={{
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.primary.contrastText,
                '&:hover': {
                  borderColor: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.light,
                },
              }}
              size="small"
            >
              Update games info
            </Button>
          </Box>
          {adminPanelComponents.map((component, index) => (
            <CustomTabPanel value={value} index={index} key={index}>
              {component}
            </CustomTabPanel>
          ))}
        </Box>
      </div>
      <CustomSnackbar
        open={open}
        onClose={handleAlertClose}
        severity={severity}
        message={message}
      />
    </ErrorBoundary>
  );
};

export default AdminPage;
