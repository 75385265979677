import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type Page } from 'types/common';
import { PAGE } from 'consts/constants';

interface CurrentPageState {
  currentPage: Page;
}

const initialState: CurrentPageState = {
  currentPage: PAGE.MAIN,
};

const currentPageSlice = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<Page>) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setCurrentPage } = currentPageSlice.actions;

export default currentPageSlice.reducer;
