import { Game, RunGameParameters } from 'types/gameService';
import { CURRENCY, GAME_MODE } from 'consts/constants';
import { useEffect, useState } from 'react';
import GameCard from '../GameCard/GameCard';

interface GameCardSimpleModeProps {
  game: Game;
  imagePath: string | undefined;
  runGame: (gameParams: RunGameParameters) => void;
  selectedLanguage: string;
}

const GameCardSimpleMode: React.FC<GameCardSimpleModeProps> = ({
  game,
  imagePath,
  runGame,
  selectedLanguage,
}) => {
  const { gameUID, latestVersion, release } = game;
  const [version, setVersion] = useState<string>('');

  useEffect(() => {
    if (latestVersion) {
      setVersion(latestVersion);
    } else if (release?.tag) {
      setVersion(release.tag);
    }
  }, [latestVersion, release]);

  const runSimpleModeGame = () => {
    runGame({
      mode: GAME_MODE.REAL,
      gameUid: gameUID || '',
      version,
      language: selectedLanguage,
      currency: CURRENCY.EUR,
    });
  };

  return <GameCard gameName={imagePath} imagePath={imagePath} runGame={runSimpleModeGame} />;
};

export default GameCardSimpleMode;
