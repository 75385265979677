import UserInfo from './UserInfo';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import UserForm from 'components/UserForm/UserForm';
import { useState } from 'react';
import { fetchUserInfo } from 'store/slices/userDataSlice';
import { PERMISSION_TITLE, USER_FORM_ACTION } from 'consts/constants';
import { useHasPermission } from 'hooks/useHasPermission';

const UserInfoContainer = () => {
  const user = useAppSelector((state) => state.user.info);
  const { isDisplayCredit } = useAppSelector((state) => state.stateUI);
  const userHasManagementPermission = useHasPermission([PERMISSION_TITLE.USER_MANAGEMENT]);

  const [isOpenUserForm, setIsOpenUserForm] = useState(false);

  const dispatch = useAppDispatch();

  const handleSuccessCallback = () => {
    dispatch(fetchUserInfo());
  };

  if (!user) return null;

  const { title } = user.role;

  const isAdmin = title === 'admin';

  return (
    <>
      <UserInfo
        userName={user.nickname}
        isDisplayCredit={isDisplayCredit}
        setIsOpenUserForm={setIsOpenUserForm}
        userHasManagementPermission={userHasManagementPermission}
      />
      <UserForm
        user={user}
        isOpenUserForm={isOpenUserForm}
        setIsOpenUserForm={setIsOpenUserForm}
        userFormAction={USER_FORM_ACTION.EDIT}
        titleUserForm="Edit user"
        isAdmin={isAdmin}
        onSuccessCallback={handleSuccessCallback}
      />
    </>
  );
};

export default UserInfoContainer;
