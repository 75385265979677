import { useAppSelector } from 'hooks/useRedux';
import { PermissionTitle } from 'types/user';
import { hasAnyPermission } from '../utils/user';

export function useHasPermission(requiredPermissionTitles: PermissionTitle[]): boolean {
  const user = useAppSelector((state) => state.user.info);
  const isAuth = useAppSelector((state) => state.userAuth.isAuth);

  if (!user || !isAuth) return false;

  return hasAnyPermission(user.role.permissions, requiredPermissionTitles);
}
