import { Link } from 'react-router-dom';
import { AppBar, Container, IconButton, Toolbar } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { type Page } from 'types/common';
import styles from './header.module.scss';
import { PAGE, ROUTE } from 'consts/constants';
import NavigationLinks from './links/NavigationLinks';
import ButtonsGroup from '../ButtonsGroup/ButtonsGroup';
import { useState } from 'react';
import UserInfoContainer from './userInfo/UserInfoContainer';
import classNames from 'classnames';

interface HeaderProps {
  isAuth: boolean;
  handleLogout: () => void;
  currentPage: Page;
  isBaseInterface: boolean;
  isAdminPageAllowed: boolean;
  isEnvsPageAllowed: boolean;
}

const Header: React.FC<HeaderProps> = ({
  isAuth,
  handleLogout,
  currentPage,
  isBaseInterface,
  isAdminPageAllowed,
  isEnvsPageAllowed,
}) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const toggleMenuActive = () => {
    setIsMenuActive(!isMenuActive);
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: 2,
      }}
    >
      <Toolbar>
        <Container
          className={styles.header}
          sx={{
            display: 'flex',
            justifyContent: {
              sm: 'space-between',
            },
            alignItems: 'center',
          }}
          maxWidth="xl"
        >
          <Link to={ROUTE.MAIN}>
            <img
              src="/img/logo-octavian-horizontal.svg"
              alt="Octavian games"
              className={styles.logo}
            />
          </Link>
          {currentPage !== PAGE.LOGIN && isBaseInterface && (
            <NavigationLinks
              isEnvsPageAllowed={isEnvsPageAllowed}
              isMenuActive={isMenuActive}
              toggleMenuActive={toggleMenuActive}
            />
          )}
          {isAuth ? (
            <div
              className={classNames(
                styles.userInfoAndButtonsGroup,
                `${!isBaseInterface ? styles.userInfoAndButtonsGroup_demoInterface : ''}`,
              )}
            >
              {currentPage === PAGE.MAIN && <UserInfoContainer />}
              <ButtonsGroup
                isAdminPageAllowed={isAdminPageAllowed}
                currentPage={currentPage}
                handleLogout={handleLogout}
                isMenuActive={isMenuActive}
                toggleMenuActive={toggleMenuActive}
                isBaseInterface={isBaseInterface}
              />
            </div>
          ) : (
            <IconButton color="inherit" className={styles.iconBtn}>
              <Link to={ROUTE.LOGIN}>
                <AccountCircle
                  sx={{ height: '2rem', width: '2rem', margin: '0.5rem', color: 'white' }}
                />
              </Link>
            </IconButton>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
