import { LicensedSiteListResponse } from 'types/backendService';
import { type IntegrationBackend } from 'types/common';
import $api from 'utils/http';

export async function getLicensedSiteList(
  env: string,
  backend: IntegrationBackend,
  signal?: AbortSignal,
): Promise<LicensedSiteListResponse> {
  return $api.get('backend/sites', {
    params: {
      env,
      backend,
    },
    signal,
  });
}
