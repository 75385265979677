import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPermissions } from 'api/AdministrationPermission';
import { Permission } from 'types/user';

interface PermissionsState {
  permissions: Permission[];
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: PermissionsState = {
  permissions: [],
  isLoading: false,
  error: null,
};

export const fetchPermissions = createAsyncThunk<Permission[], void, { rejectValue: string }>(
  'permissions/fetchPermissions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPermissions();
      return response.data.permissions;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default permissionsSlice.reducer;
