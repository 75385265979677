import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getConfiguredCurrencies } from 'api/Game';
import { CurrencyConfig, ConfiguredCurrency } from 'types/configuredCurrency';
import { parseCurrencyConfig } from 'utils/currency';

export interface CurrencyState {
  configuredCurrencies: ConfiguredCurrency[];
  currentCurrency?: ConfiguredCurrency;
  funCurrencyConfig?: CurrencyConfig;
  error: string | null;
}

const initialState: CurrencyState = {
  configuredCurrencies: [],
  error: null,
};

export const fetchConfiguredCurrencies = createAsyncThunk<
  ConfiguredCurrency[],
  string,
  { rejectValue: string }
>('currency/fetchConfiguredCurrencies', async (environment, { rejectWithValue }) => {
  try {
    const {
      data: { currencies },
    } = await getConfiguredCurrencies(environment);
    return currencies.map(parseCurrencyConfig);
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrentCurrency: (state, action: PayloadAction<string>) => {
      state.currentCurrency = state.configuredCurrencies.find(
        (currency) => currency.id === action.payload,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguredCurrencies.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchConfiguredCurrencies.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch configured currencies';
      })
      .addCase(fetchConfiguredCurrencies.fulfilled, (state, action) => {
        const currencies = action.payload;
        state.configuredCurrencies = currencies;
        state.funCurrencyConfig = currencies.find(
          (currency) => currency.id === 'FUN',
        )?.currencyConfig;

        if (!state.currentCurrency && currencies.length > 0) {
          state.currentCurrency = currencies.at(0);
        }
      });
  },
});

export const { setCurrentCurrency } = currencySlice.actions;
export default currencySlice.reducer;
