import FilterPanel from './FilterPanel';
import {
  setEnvironment,
  setFilterOnlyClassesA,
  setIntegrationBackend,
  setService,
  setCategorySort,
  setSortOrder,
  setLaunchGameMode,
  setGlobalLanguage,
  setSelectedLicensedSite,
  toggleWorkingGamesStateForPage,
} from 'store/slices/configUISlice';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { SelectChangeEvent } from '@mui/material';
import { type CategorySort, type GameSortOrder, type IntegrationBackend } from 'types/common';
import { INTEGRATION_BACKEND, PAGE, SERVICE } from 'consts/constants';
import { EnvLanguage } from 'types/gameService';
import { changeStatusOfApplyingGlobalLanguage } from 'store/slices/gameLaunchSettingsSlice';
import { LicensedSite } from 'types/backendService';

interface FilterPanelContainerProps {
  envLanguages: EnvLanguage[];
  licensedSites: LicensedSite[];
}

export const FilterPanelContainer: React.FC<FilterPanelContainerProps> = ({
  envLanguages,
  licensedSites,
}) => {
  const dispatch = useAppDispatch();

  const { allowedEnvironments } = useAppSelector((state) => state.user);

  const {
    integrationBackend,
    selectedLicensedSite,
    environment,
    workingGamesSwitcherStates: { main: isOnlyWorkingGames },
    isOnlyClassesA,
    categorySort,
    sortOrder,
    service,
    isSimpleLaunchGameMode,
    globalLanguage,
  } = useAppSelector((state) => state.stateUI);

  const handleStatusGame = () => {
    dispatch(toggleWorkingGamesStateForPage(PAGE.MAIN));
  };

  const handleTypeClass = () => {
    dispatch(setFilterOnlyClassesA());
  };

  const handleCategorySortChange = (event: SelectChangeEvent<string>) => {
    dispatch(setCategorySort(event.target.value as CategorySort));
  };

  const handleSortOrderChange = (value: GameSortOrder) => {
    dispatch(setSortOrder(value));
  };

  const handleChangeService = (event: SelectChangeEvent<string>) => {
    const selectedService = event.target.value;

    dispatch(setService(selectedService));

    if (selectedService === SERVICE.DMS) {
      dispatch(setIntegrationBackend(INTEGRATION_BACKEND.LEGACY));
    }
  };

  const handleChangeEnvironment = (env: string) => {
    dispatch(setEnvironment(env));
  };

  const handleIntegrationBackground = (event: SelectChangeEvent<string>) => {
    dispatch(setIntegrationBackend(event.target.value as IntegrationBackend));
  };

  const handleLicense = (event: SelectChangeEvent<string>) => {
    dispatch(setSelectedLicensedSite(event.target.value));
  };

  const handleLaunchGameMode = () => {
    dispatch(setLaunchGameMode());
  };

  const handleGlobalLanguage = (event: SelectChangeEvent<string>) => {
    dispatch(changeStatusOfApplyingGlobalLanguage(true));
    dispatch(setGlobalLanguage(event.target.value));
  };

  return (
    <FilterPanel
      integrationBackend={integrationBackend}
      handleIntegrationBackground={handleIntegrationBackground}
      isOnlyWorkingGames={isOnlyWorkingGames}
      handleStatusGame={handleStatusGame}
      isOnlyClassesA={isOnlyClassesA}
      handleTypeClass={handleTypeClass}
      categorySort={categorySort}
      handleCategorySortChange={handleCategorySortChange}
      sortOrder={sortOrder}
      handleSortOrderChange={handleSortOrderChange}
      service={service}
      handleChangeService={handleChangeService}
      environment={environment}
      handleChangeEnvironment={handleChangeEnvironment}
      environments={allowedEnvironments}
      isSimpleLaunchGameMode={isSimpleLaunchGameMode}
      handleLaunchGameMode={handleLaunchGameMode}
      globalLanguage={globalLanguage}
      envLanguages={envLanguages}
      handleGlobalLanguage={handleGlobalLanguage}
      selectedLicensedSite={selectedLicensedSite}
      licensedSites={licensedSites}
      handleLicense={handleLicense}
    />
  );
};
