import { SetStateAction, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'hooks/useRedux';
import { showSnackbar } from 'store/slices/snackbarSlice';
import { changeUserInfo, createNewUser } from 'api/AdministrationUser';
import { removeEmptyObjectValues } from 'utils/object';
import { ChangeUserAPIParams, CreateNewUserAPIParams } from 'types/adminService';
import { type UserFormAction } from 'types/common';
import { RoleInfo, User } from 'types/user';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import styles from './userForm.module.scss';
import { USER_FORM_ACTION } from 'consts/constants';

interface UserFormProps {
  user: User;
  isAdmin: boolean;
  userFormAction: UserFormAction;
  titleUserForm: string;
  isOpenUserForm: boolean;
  setIsOpenUserForm: React.Dispatch<SetStateAction<boolean>>;
  roles?: RoleInfo[];
  onSuccessCallback: () => void;
}

const UserForm: React.FC<UserFormProps> = ({
  user,
  isAdmin,
  userFormAction,
  titleUserForm,
  isOpenUserForm,
  setIsOpenUserForm,
  roles,
  onSuccessCallback,
}) => {
  const dispatch = useAppDispatch();

  const defaultValues = useMemo(
    () => ({
      nickname: user.nickname,
      password: '',
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company,
      email: user.email,
      roleId: user.role?.id,
    }),
    [user],
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  useEffect(() => {
    if (userFormAction === USER_FORM_ACTION.ADD) {
      reset();
    }
  }, [reset, userFormAction]);

  const handleClose = () => {
    setIsOpenUserForm(false);
  };

  const onSubmitAddingUser = async (data: CreateNewUserAPIParams) => {
    try {
      const filteredData = removeEmptyObjectValues<CreateNewUserAPIParams>(data);
      await createNewUser(filteredData);
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  const onSubmitEditingUser = async (data: ChangeUserAPIParams) => {
    try {
      let filteredData = filterDataByDefaultValues(data, defaultValues);
      filteredData = removeEmptyObjectValues<ChangeUserAPIParams>(filteredData);
      await changeUserInfo({ id: user.id, data: filteredData });
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccess = () => {
    onSuccessCallback();
    dispatch(
      showSnackbar({
        message: `User successfully ${userFormAction === USER_FORM_ACTION.ADD ? 'added' : 'changed'}`,
        severity: 'success',
      }),
    );
    handleClose();
  };

  const handleError = (error) => {
    const errorMessage = (error as Error).message;
    dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    console.error(errorMessage);
  };

  return (
    <Dialog open={isOpenUserForm} onClose={handleClose}>
      <DialogTitle className={styles.dialogTitle}>{titleUserForm}</DialogTitle>
      <DialogContent sx={{ paddingBottom: '5px' }}>
        <form
          onSubmit={handleSubmit(
            userFormAction === USER_FORM_ACTION.ADD ? onSubmitAddingUser : onSubmitEditingUser,
          )}
          className={styles.form}
        >
          <div className={styles.formContent}>
            <div className={styles.fieldForm}>
              <TextField
                label="Nickname"
                variant="standard"
                className={styles.formInput}
                placeholder="Nickname"
                autoFocus
                {...register('nickname', { required: 'Required field' })}
                error={!!errors.nickname}
                helperText={errors?.nickname ? errors.nickname.message : null}
                disabled={userFormAction !== USER_FORM_ACTION.ADD}
              />
            </div>

            {userFormAction === USER_FORM_ACTION.ADD && (
              <div className={styles.fieldForm}>
                <TextField
                  label="Password"
                  variant="standard"
                  className={styles.formInput}
                  placeholder="Password"
                  {...register('password', { required: 'Required field' })}
                  error={!!errors.password}
                  helperText={errors?.password ? errors.password.message : null}
                />
              </div>
            )}

            <div className={styles.fieldForm}>
              <TextField
                label="First name"
                variant="standard"
                className={styles.formInput}
                placeholder="First name"
                {...register('firstName')}
              />
            </div>

            <div className={styles.fieldForm}>
              <TextField
                label="Last name"
                variant="standard"
                className={styles.formInput}
                placeholder="Last name"
                {...register('lastName')}
              />
            </div>

            <div className={styles.fieldForm}>
              <TextField
                label="Email"
                variant="standard"
                className={styles.formInput}
                placeholder="Email"
                {...register('email')}
              />
            </div>

            <div className={styles.fieldForm}>
              <TextField
                label="Company"
                variant="standard"
                className={styles.formInput}
                placeholder="Company"
                {...register('company')}
                error={!!errors.company}
                helperText={errors?.company ? errors.company.message : null}
              />
            </div>

            {isAdmin && roles && roles?.length > 0 && (
              <div className={styles.fieldForm}>
                <TextField
                  sx={{ width: '200px' }}
                  select
                  label="Role ID"
                  variant="standard"
                  className={styles.formInput}
                  defaultValue={user.role?.id ? user.role.id : ''}
                  {...register('roleId', { required: 'Required field' })}
                  error={!!errors.roleId}
                  helperText={errors?.roleId ? errors.roleId.message : null}
                  disabled={!isAdmin}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.title}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )}
          </div>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', width: '200px' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">
              {userFormAction === USER_FORM_ACTION.ADD ? 'Add' : 'Apply'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserForm;

function filterDataByDefaultValues<T extends object>(data: T, defaultValues: T): T {
  return Object.keys(defaultValues).reduce((filteredData, key) => {
    if (key in data) {
      filteredData[key as keyof T] = data[key as keyof T];
    }
    return filteredData;
  }, {} as T);
}
