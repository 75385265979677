import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';

interface CustomSnackbarProps {
  open: boolean;
  onClose: (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void;
  severity: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, onClose, severity, message }) => {
  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose(event, reason);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
