import { GameSettingInterface } from './GameSettingInterface';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setGameScreen } from 'store/slices/configUISlice';

export const GameSettingInterfaceContainer: React.FC = () => {
  const { isFullScreenMode } = useAppSelector((state) => state.stateUI);
  const dispatch = useAppDispatch();

  const handleStatusFullScreen = () => {
    dispatch(setGameScreen());
  };

  return (
    <GameSettingInterface
      handleStatusFullScreen={handleStatusFullScreen}
      isFullScreenMode={isFullScreenMode}
    ></GameSettingInterface>
  );
};
