import { FC } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface MissettledTransactionFeatureProps {
  isActive: boolean;
  onToggle: (value: boolean) => void;
}

const MissettledTransactionFeature: FC<MissettledTransactionFeatureProps> = ({
  isActive,
  onToggle,
}) => (
  <FormControlLabel
    control={<Switch checked={isActive} onChange={(event) => onToggle(event.target.checked)} />}
    label="Missettled Transaction"
    labelPlacement="start"
  />
);

export default MissettledTransactionFeature;
