import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import styles from './GameSettingInterface.module.scss';

interface GameSettingInterfaceProps {
  isFullScreenMode: boolean;
  handleStatusFullScreen: () => void;
}

export const GameSettingInterface: React.FC<GameSettingInterfaceProps> = ({
  isFullScreenMode,
  handleStatusFullScreen,
}) => {
  return (
    <FormGroup className={styles.formGroup}>
      <FormControlLabel
        control={<Switch checked={isFullScreenMode} onChange={handleStatusFullScreen} />}
        label="Full screen mode"
        labelPlacement="start"
      />
    </FormGroup>
  );
};
