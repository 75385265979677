import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLegacyGameURL, getOSIGameURL } from 'api/Game';
import { LegacyBackendGameURLParams, OSIBackendOpenGameURLParams } from 'types/gameService';
import { INTEGRATION_BACKEND } from 'consts/constants';

export interface LegacyBackendGameParams {
  integrationBackend: typeof INTEGRATION_BACKEND.LEGACY;
  urlParams: LegacyBackendGameURLParams;
}

export interface OSIBackendGameParams {
  integrationBackend: typeof INTEGRATION_BACKEND.OSI;
  urlParams: OSIBackendOpenGameURLParams;
}

interface CurrentGameState {
  URL: string;
  error: string | null | undefined;
}

const initialState: CurrentGameState = {
  URL: '',
  error: null,
};

export const fetchGameURL = createAsyncThunk<
  string,
  LegacyBackendGameParams | OSIBackendGameParams,
  { rejectValue: string }
>(
  'currentGame/fetchGameURL',
  async ({ integrationBackend, urlParams }, { rejectWithValue, dispatch }) => {
    try {
      const response =
        integrationBackend === INTEGRATION_BACKEND.LEGACY
          ? await getLegacyGameURL(urlParams)
          : await getOSIGameURL(urlParams);
      const URL = response.data.url;
      dispatch(setCurrentGameURL(URL));
      return URL;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

const currentGameSlice = createSlice({
  name: 'currentGame',
  initialState,
  reducers: {
    setCurrentGameURL(state, action: PayloadAction<string>) {
      state.URL = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGameURL.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchGameURL.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setCurrentGameURL } = currentGameSlice.actions;

export default currentGameSlice.reducer;
