import {
  styled,
  tableCellClasses,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import styles from './usersTagsTable.module.scss';
import UsersTagsTableRow from './TableRow/UsersTagsTableRow';
import { Tag, UserTag } from 'types/adminService';
import { User } from 'types/user';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface UsersTagsTableProps {
  users: User[];
  tags: Tag[];
  handleAddTag: (newTag: UserTag) => void;
  handleRemoveTag: (userId: number, tagId: number) => Promise<void>;
}

const UsersTagsTable: React.FC<UsersTagsTableProps> = ({
  users,
  tags,
  handleAddTag,
  handleRemoveTag,
}) => {
  return (
    <TableContainer sx={{ borderRadius: 0 }} component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className={styles.tableHead}>
          <TableRow>
            <StyledTableCell align="center">Nickname</StyledTableCell>
            <StyledTableCell align="center">FirstName</StyledTableCell>
            <StyledTableCell align="center">Company</StyledTableCell>
            <StyledTableCell align="center">Tags</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <UsersTagsTableRow
              key={user.id}
              user={user}
              tags={tags}
              handleAddTag={handleAddTag}
              handleRemoveTag={handleRemoveTag}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTagsTable;
