import {
  CATEGORY_SORT,
  ENVIRONMENT_WITHOUT_LIVE,
  GAME_MODE,
  INTEGRATION_BACKEND,
  SERVICE,
} from './constants';

export const ENVIRONMENT_SELECT_OPTIONS = Object.values(ENVIRONMENT_WITHOUT_LIVE).map((env) => ({
  value: env,
  label: env,
}));

export const SERVICE_SELECT_OPTIONS = Object.values(SERVICE).map((service) => ({
  value: service,
  label: service,
}));

export const INTEGRATION_BACKEND_SELECT_OPTIONS = Object.values(INTEGRATION_BACKEND).map(
  (backend) => ({
    value: backend,
    label: backend,
  }),
);

export const GAME_MODE_SELECT_OPTIONS = Object.values(GAME_MODE).map((gameMode) => ({
  value: gameMode,
  label: gameMode,
}));

export const CATEGORY_SORT_SELECT_OPTIONS = Object.values(CATEGORY_SORT).map((sortOption) => ({
  value: sortOption,
  label: sortOption,
}));
