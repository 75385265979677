import { useMemo } from 'react';
import { useAppSelector } from 'hooks/useRedux';
import { getAllowedEnvironments, getInitEnvironment } from 'utils/user';
import { Page } from 'types/common';

export function usePageAllowedEnvironments(page: Page): {
  allowedEnvironments: string[];
  initEnvironment: string;
} {
  const permissions = useAppSelector((state) => state.user.info?.role.permissions);

  const allowedEnvironments = useMemo(
    () => (permissions ? getAllowedEnvironments(permissions, page) : []),
    [permissions, page],
  );

  const initEnvironment = useMemo(
    () => getInitEnvironment(allowedEnvironments),
    [allowedEnvironments],
  );

  return { allowedEnvironments, initEnvironment };
}
