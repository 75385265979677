import { z } from 'zod';
import {
  ENVIRONMENT_WITHOUT_LIVE,
  GAME_MODE,
  INTEGRATION_BACKEND,
  SERVICE,
} from '../consts/constants';

export const gameMetadataSchema = z.object({
  developer: z.string().optional(),
});

export const validateGameMetadata = (data: { developer: string }) => {
  return gameMetadataSchema.safeParse(data);
};

export const UserSettingsSchema = z.object({
  environment: z
    .string()
    .refine((value) => Object.values(ENVIRONMENT_WITHOUT_LIVE).includes(value), {
      message: 'Environment must be one of the allowed environments',
    }),
  currency: z.string().min(1),
  integrationBackend: z.nativeEnum(INTEGRATION_BACKEND),
  license: z.string(),
  service: z.string().refine((value) => Object.values(SERVICE).includes(value), {
    message: 'Service must be one of the allowed services',
  }),
  mode: z.nativeEnum(GAME_MODE),
  language: z.string().min(1),
  isFullScreenMode: z.boolean(),
  isDisplayCredit: z.boolean(),
  creditLimit: z.number(),
});

export const validateUserSettings = (data: { settings: string }) => {
  return UserSettingsSchema.safeParse(data);
};

export type ValidatedUserGameSettings = z.infer<typeof UserSettingsSchema>;
