import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CATEGORY_SORT,
  DEFAULT_SELECTED_LICENSED_SITE,
  ENVIRONMENT,
  GAME_SORT_ORDER,
  INTEGRATION_BACKEND,
  LANGUAGE,
  PAGE,
  SERVICE,
} from 'consts/constants';
import { type CategorySort, type GameSortOrder, type IntegrationBackend } from 'types/common';
import { ValidatedUserGameSettings } from 'utils/validation';

type PageWithWorkingGamesSwitcher = typeof PAGE.MAIN | typeof PAGE.LIVE_ENV_MONITORING;

type WorkingGamesSwitcherStates = Record<PageWithWorkingGamesSwitcher, boolean>;

interface ConfigUIState {
  integrationBackend: IntegrationBackend;
  selectedLicensedSite: string;
  isOnlyClassesA: boolean;
  service: string;
  environment: string;
  isFullScreenMode: boolean;
  categorySort: CategorySort;
  sortOrder: GameSortOrder;
  isSimpleLaunchGameMode: boolean;
  globalLanguage: string;
  workingGamesSwitcherStates: WorkingGamesSwitcherStates;
  isBaseInterface: boolean;
  isDisplayCredit: boolean;
  creditLimit: number;
  hasReset: boolean;
}

const initialState: ConfigUIState = {
  integrationBackend: INTEGRATION_BACKEND.LEGACY,
  selectedLicensedSite: DEFAULT_SELECTED_LICENSED_SITE.NAME,
  isOnlyClassesA: false,
  service: SERVICE.LCS,
  environment: ENVIRONMENT.DEV,
  isFullScreenMode: true,
  categorySort: CATEGORY_SORT.DEPLOY_DATE,
  sortOrder: GAME_SORT_ORDER.NEWEST_FIRST,
  isSimpleLaunchGameMode: false,
  globalLanguage: LANGUAGE.EN,
  workingGamesSwitcherStates: {
    main: true,
    liveEnvMonitoring: true,
  },
  isBaseInterface: true,
  isDisplayCredit: true,
  creditLimit: 0,
  hasReset: false,
};

const configUISlice = createSlice({
  name: 'configUI',
  initialState,
  reducers: {
    setIntegrationBackend: (state, action: PayloadAction<IntegrationBackend>) => {
      state.integrationBackend = action.payload;
    },
    setSelectedLicensedSite: (state, action: PayloadAction<string>) => {
      state.selectedLicensedSite = action.payload;
    },
    setFilterOnlyClassesA: (state) => {
      state.isOnlyClassesA = !state.isOnlyClassesA;
    },
    setSortOrder: (state, action: PayloadAction<GameSortOrder>) => {
      state.sortOrder = action.payload;
    },
    setCategorySort: (state, action: PayloadAction<CategorySort>) => {
      state.categorySort = action.payload;
    },
    setService: (state, action: PayloadAction<string>) => {
      state.service = action.payload;
    },
    setEnvironment: (state, action: PayloadAction<string>) => {
      state.environment = action.payload;
    },
    setGameScreen: (state) => {
      state.isFullScreenMode = !state.isFullScreenMode;
    },
    setLaunchGameMode: (state) => {
      state.isSimpleLaunchGameMode = !state.isSimpleLaunchGameMode;
    },
    setGlobalLanguage: (state, action: PayloadAction<string>) => {
      state.globalLanguage = action.payload;
    },
    toggleWorkingGamesStateForPage: (
      state,
      action: PayloadAction<PageWithWorkingGamesSwitcher>,
    ) => {
      const page = action.payload;
      state.workingGamesSwitcherStates[page] = !state.workingGamesSwitcherStates[page];
    },
    setInterface: (state, action: PayloadAction<boolean>) => {
      state.isBaseInterface = action.payload;
    },
    applyUserSettings: (state, action: PayloadAction<ValidatedUserGameSettings>) => {
      const {
        integrationBackend,
        service,
        license,
        environment,
        language,
        isFullScreenMode,
        isDisplayCredit,
        creditLimit,
      } = action.payload;

      state.environment = environment;
      state.service = service;
      state.integrationBackend = integrationBackend;
      state.selectedLicensedSite = license;
      state.globalLanguage = language;
      state.isFullScreenMode = isFullScreenMode;
      state.isDisplayCredit = isDisplayCredit;
      state.creditLimit = creditLimit;
    },
    resetToDefaultState: (state) => {
      Object.assign(state, initialState);
      state.hasReset = true;
    },
    clearResetStatus: (state) => {
      state.hasReset = false;
    },
  },
});

export const {
  setIntegrationBackend,
  setSelectedLicensedSite,
  setFilterOnlyClassesA,
  setSortOrder,
  setCategorySort,
  setService,
  setEnvironment,
  setGameScreen,
  setLaunchGameMode,
  setGlobalLanguage,
  toggleWorkingGamesStateForPage,
  setInterface,
  applyUserSettings,
  resetToDefaultState,
  clearResetStatus,
} = configUISlice.actions;

export default configUISlice.reducer;
