import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getBalanceForAuthenticatedUser, getSettings, getUserInfo } from 'api/User';
import { type Balance, type User } from 'types/user';
import { ValidatedUserGameSettings, validateUserSettings } from 'utils/validation';
import { logout } from './userAuthSlice';
import { applyUserSettings } from './configUISlice';
import { showSnackbar } from './snackbarSlice';
import { BALANCE_MODE } from 'consts/constants';

interface CurrentBalanceData {
  real: Balance;
  fun: Balance;
}

interface UserState {
  info: User | undefined;
  currentBalanceData: CurrentBalanceData;
  allowedEnvironments: string[];
  gameSettings?: ValidatedUserGameSettings;
  isNewUser: boolean;
}

const initialState: UserState = {
  info: undefined,
  currentBalanceData: {
    real: { mode: BALANCE_MODE.REAL, value: 0 },
    fun: { mode: BALANCE_MODE.FUN, value: 0 },
  },
  allowedEnvironments: [],
  isNewUser: true,
};

export const fetchUserInfo = createAsyncThunk(
  'userData/getUserInfo',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUserInfo();
      const { user } = response.data;
      return user;
    } catch (error) {
      const errorMessage = (error as Error).message;
      console.error(errorMessage);
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      return rejectWithValue(errorMessage);
    }
  },
);

export const fetchUserBalance = createAsyncThunk(
  'userData/getUserBalance',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getBalanceForAuthenticatedUser();
      const { balance } = response.data;
      dispatch(updateBalance(balance));
      return balance;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const fetchAndApplyUserSettings = createAsyncThunk(
  'userData/fetchUserSettings',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getSettings();
      const { settings } = response.data;
      const parsedData = JSON.parse(settings);
      const validationResult = validateUserSettings(parsedData);

      if (validationResult.success) {
        dispatch(applyUserSettings(validationResult.data));
        return validationResult.data;
      } else {
        dispatch(logout());
        dispatch(resetUserInfo());
        const { path, message } = validationResult.error.errors[0];
        return rejectWithValue(`${path} ${message}`);
      }
    } catch (error) {
      dispatch(logout());
      dispatch(resetUserInfo());
      return rejectWithValue((error as Error).message);
    }
  },
);

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User>) => {
      state.isNewUser = state.info?.id !== action.payload.id;
      state.info = action.payload;
      updateBalanceData(state, action.payload.balance);
    },
    updateBalance: (state, action: PayloadAction<Balance[]>) => {
      updateBalanceData(state, action.payload);
    },
    setAllowedEnvironments: (state, action: PayloadAction<string[]>) => {
      state.allowedEnvironments = action.payload;
    },

    resetUserInfo: (state) => {
      state.gameSettings = undefined;
      state.info = undefined;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAndApplyUserSettings.fulfilled,
        (state, action: PayloadAction<ValidatedUserGameSettings>) => {
          state.gameSettings = action.payload;
        },
      )
      .addCase(fetchAndApplyUserSettings.rejected, (state, action) => {
        console.error(action.payload);
        alert(
          'There seems to be an issue with your account settings. Please reach out to the administrator who configured them for assistance.',
        );
      })
      .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<User>) => {
        state.info = action.payload;
      });
  },
});

export const { setUserData, updateBalance, setAllowedEnvironments, resetUserInfo } =
  userDataSlice.actions;

export default userDataSlice.reducer;

function updateBalanceData(state: UserState, balance: Balance[]): void {
  const creditReal = balance.find((balance) => balance.mode === BALANCE_MODE.REAL)?.value || 0;
  const creditFun = balance.find((balance) => balance.mode === BALANCE_MODE.FUN)?.value || 0;
  state.currentBalanceData.real.value = creditReal;
  state.currentBalanceData.fun.value = creditFun;
}
