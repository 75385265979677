import $api from 'utils/http';
import { type Balance } from 'types/user';
import {
  DataBalanceResponse,
  MissettledTransactionFeatureStateResponse,
  UserInfoResponse,
} from '../types/userService';
import { UserSettingsResponse } from '../types/adminService';

export async function getBalanceForAuthenticatedUser(): Promise<DataBalanceResponse> {
  return $api.get('user/balance');
}

export async function updateBalanceForAuthenticatedUser({
  balance,
}: {
  balance: Balance[];
}): Promise<DataBalanceResponse> {
  return $api.post('user/balance', { balance });
}

export async function getMissettledTransactionFeatureState(
  signal: AbortSignal,
): Promise<MissettledTransactionFeatureStateResponse> {
  return $api.get('user/test/missettledtr', {
    signal,
  });
}

export async function setMissettledTransactionFeatureState(
  missettledTransactionTestRequest: boolean,
): Promise<MissettledTransactionFeatureStateResponse> {
  return $api.post('user/test/missettledtr', { missettledTransactionTestRequest });
}

export async function getSettings(): Promise<UserSettingsResponse> {
  return $api.get('user/settings/interface/base');
}

export async function getUserInfo(): Promise<UserInfoResponse> {
  return $api.get('user/info');
}
