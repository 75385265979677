import { ENVIRONMENT } from 'consts/constants';
import { type IntegrationBackend } from 'types/common';
import {
  GamesInfoResponse,
  OpenGameResponse,
  LegacyBackendGameURLParams,
  OSIBackendOpenGameURLParams,
  EnvironmentLanguageInfoResponse,
  ConfiguredCurrenciesResponse,
} from 'types/gameService';
import { Status } from 'types/service';
import $api from 'utils/http';

export async function getLegacyGameURL(
  data: LegacyBackendGameURLParams,
): Promise<OpenGameResponse> {
  return $api.post('games/legacy/open', data);
}

export async function getOSIGameURL(data: OSIBackendOpenGameURLParams): Promise<OpenGameResponse> {
  return $api.post('games/osi/open', data);
}

export async function getGames(
  env = ENVIRONMENT.DEV,
  signal?: AbortSignal,
): Promise<GamesInfoResponse> {
  return $api.get('games/info?', {
    params: { env },
    signal,
  });
}

export async function resetGameState(
  backend: IntegrationBackend,
  gameUid: string,
  env: string,
  gameMode: string,
  site: string,
): Promise<Status> {
  return $api.put(`games/${backend}/${gameUid}`, null, {
    params: {
      env,
      gameMode,
      site,
    },
  });
}

export async function getLanguagesInfo(
  env = ENVIRONMENT.DEV,
  signal?: AbortSignal,
): Promise<EnvironmentLanguageInfoResponse> {
  return $api.get('games/languages?', {
    params: {
      env,
    },
    signal,
  });
}

export async function getConfiguredCurrencies(env: string): Promise<ConfiguredCurrenciesResponse> {
  return $api.get('games/currencies?', {
    params: {
      env,
    },
  });
}
