import { User } from 'types/user';
import { extractUserBalance } from 'utils/user';
import { styled, TableCell, tableCellClasses } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import styles from './UsersTableRow.module.scss';
import BalanceTableCell from './BalanceCell/BalanceTableCell';
import { CurrencyConfig, ConfiguredCurrency } from 'types/configuredCurrency';
import { CustomButton } from 'components/CustomButton';
import { BALANCE_MODE } from 'consts/constants';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface UsersTableRowProps {
  user: User;
  currentCurrency: ConfiguredCurrency | undefined;
  funCurrencyConfig: CurrencyConfig | undefined;
  handleOpenCreditForm: ({
    realBalance,
    funBalance,
    userId,
  }: {
    realBalance: number;
    funBalance: number;
    userId: number;
  }) => Promise<void>;
  changeUser: (nickname: string) => Promise<void>;
  changePassword: (id: number) => Promise<void>;
  handleClickRemoveUser: (user: User) => void;
  handleOpenGameLaunchSettings: (user: User) => void;
}

const UsersTableRow: React.FC<UsersTableRowProps> = ({
  user,
  currentCurrency,
  funCurrencyConfig,
  handleOpenCreditForm,
  changeUser,
  changePassword,
  handleClickRemoveUser,
  handleOpenGameLaunchSettings,
}) => {
  const realBalance =
    extractUserBalance({ userBalance: user.balance, balanceMode: BALANCE_MODE.REAL }) ?? 0;
  const funBalance =
    extractUserBalance({ userBalance: user.balance, balanceMode: BALANCE_MODE.FUN }) ?? 0;

  const handleClickOpenCreditForm = () =>
    handleOpenCreditForm({ realBalance, funBalance, userId: user.id });

  return (
    <StyledTableRow>
      <StyledTableCell align="center" component="th" scope="row">
        {user.nickname}
      </StyledTableCell>
      <StyledTableCell align="center">{user.firstName}</StyledTableCell>
      <StyledTableCell align="center">{user.role.title}</StyledTableCell>
      {currentCurrency && (
        <>
          <BalanceTableCell
            balance={realBalance}
            currencyConfig={currentCurrency.currencyConfig}
            handleClickOpenCreditForm={handleClickOpenCreditForm}
          />
          {funCurrencyConfig && (
            <BalanceTableCell
              balance={funBalance}
              currencyConfig={funCurrencyConfig}
              handleClickOpenCreditForm={handleClickOpenCreditForm}
            />
          )}
        </>
      )}
      <StyledTableCell align="center">
        <div className={styles.btnGroup}>
          <CustomButton onClick={() => handleOpenGameLaunchSettings(user)} label="UI settings" />
          <CustomButton onClick={() => changeUser(user.nickname)} label="Edit" />
          <CustomButton onClick={() => changePassword(user.id)} label="Reset password" />
          <CustomButton onClick={() => handleClickRemoveUser(user)} label="Remove" />
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default UsersTableRow;
