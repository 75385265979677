import { useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GAME_MODE } from 'consts/constants';
import styles from './resetGame.module.scss';

interface ResetGameProps {
  handleResetGameState: (gameMode: string) => Promise<void>;
}

const ResetGame: React.FC<ResetGameProps> = ({ handleResetGameState }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedMode, setSelectedMode] = useState<string>(GAME_MODE.REAL);

  const handleMenuItemClick = (mode: string): void => {
    setSelectedMode(mode);
    setOpen(false);
  };

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const resetGame = (): void => {
    handleResetGameState(selectedMode);
  };

  const closeMenu = (event: Event): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={styles.resetGameComponent}>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="Button group with a nested menu">
        <Button onClick={resetGame}>{`Reset game state for ${selectedMode} mode`}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select game mode for reset"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeMenu}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {Object.values(GAME_MODE).map((mode) => (
                    <MenuItem
                      key={mode}
                      onClick={() => handleMenuItemClick(mode)}
                      selected={mode === selectedMode}
                    >
                      {`Reset game state for ${mode} mode`}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default ResetGame;
