import { GameInfoResponseData, GameMetadataResponseData } from 'types/adminService';
import $api from 'utils/http';

export async function getGamesInfo(signal?: AbortSignal): Promise<GameInfoResponseData> {
  return $api.get('admin/game-managment/games', {
    signal,
  });
}

export async function updateInfo() {
  return $api.post('admin/game-managment/games');
}

export async function getGameMetadata(gameId: number): Promise<GameMetadataResponseData> {
  return $api.get(`admin/game-managment/games/metadata/${gameId}`);
}

export async function updateGameMetadata(gameId: number, metadata: string) {
  return $api.post(`admin/game-managment/games/metadata/${gameId}`, {
    metadata,
  });
}
