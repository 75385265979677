import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { User } from 'types/user';
import { ChangeUserAPIParams } from 'types/adminService';
import { changeUserInfo } from 'api/AdministrationUser';
import {
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import styles from './PasswordResetForm.module.scss';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';

interface PasswordResetFormProps {
  user: User;
  isOpenPasswordForm: boolean;
  setIsOpenPasswordForm: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OnSubmitParams extends ChangeUserAPIParams {
  confirm: string;
}

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({
  user,
  isOpenPasswordForm,
  setIsOpenPasswordForm,
}) => {
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  const handleAlertClose = (_event_: Event | React.SyntheticEvent<any, Event>, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { password: '', confirm: '' },
  });

  const handleClose = () => {
    setIsOpenPasswordForm(false);
  };

  const onSubmitEditingUserPassword = async (data: OnSubmitParams) => {
    try {
      const { confirm, ...passwordData } = data;
      await changeUserInfo({ id: user.id, data: passwordData });
      setOpenAlert(true);
      setSeverity('success');
      setResponseMessage('Password successfully changed');
      handleClose();
      reset();
    } catch (error) {
      setOpenAlert(true);
      setSeverity('error');
      setResponseMessage((error as Error).message);
      console.error((error as Error).message);
    }
  };

  const validateConfirmPassword = (value: string) => {
    const password = watch('password');
    return value === password || 'Passwords do not match';
  };

  return (
    <>
      <Dialog className={styles.passwordDialog} open={isOpenPasswordForm} onClose={handleClose}>
        <DialogTitle className={styles.passwordDialogTitle}>
          Set password: {user.nickname}
        </DialogTitle>
        <DialogContent className={styles.passwordDialogContent} sx={{ paddingBottom: '5px' }}>
          <form onSubmit={handleSubmit(onSubmitEditingUserPassword)}>
            <div className={styles.passwordForm}>
              <div className={styles.fieldForm}>
                <TextField
                  label="Password"
                  variant="standard"
                  className={styles.passwordInput}
                  placeholder="Password"
                  autoFocus
                  type="password"
                  {...register('password', { required: 'Required field' })}
                  error={!!errors.password}
                  helperText={errors?.password ? errors.password.message : null}
                />
              </div>
              <div className={styles.fieldForm}>
                <TextField
                  label="Confirm"
                  variant="standard"
                  type="password"
                  className={styles.passwordInput}
                  placeholder="Confirm"
                  {...register('confirm', {
                    required: 'Required field',
                    validate: validateConfirmPassword,
                  })}
                  error={!!errors.confirm}
                  helperText={errors?.confirm ? errors.confirm.message : null}
                />
              </div>
            </div>

            <DialogActions className={styles.buttonsGroup}>
              <Button type="submit">Update</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <CustomSnackbar
        open={openAlert}
        onClose={handleAlertClose}
        severity={severity}
        message={responseMessage}
      />
    </>
  );
};

export default PasswordResetForm;
