import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useRedux';
import { LoginContainer } from '../LoginContainer/LoginContainer';
import { setCurrentPage } from 'store/slices/currentPageSlice';
import { PAGE, PERMISSION_TITLE, ROUTE } from 'consts/constants';
import { useHasPermission } from 'hooks/useHasPermission';

const RequiredPermissions = [
  PERMISSION_TITLE.DEV_GAME_ACCESS,
  PERMISSION_TITLE.STAGE_GAME_ACCESS,
  PERMISSION_TITLE.CERT_GAME_ACCESS,
];

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(PAGE.LOGIN));
  }, [dispatch]);

  return useHasPermission(RequiredPermissions) ? (
    <Navigate to={ROUTE.MAIN} />
  ) : (
    <div id="loginBg" className="loginBg">
      <LoginContainer />
    </div>
  );
};

export default LoginPage;
