import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SelectChangeEvent } from '@mui/material';
import EnvironmentSelect from './EnvironmentSelect/EnvironmentSelect';
import GamesTable from './GamesTable/GamesTable';
import { ErrorFallback } from 'components/ErrorFallback/ErrorFallback';
import { Preloader } from 'components/Preloader/Preloader';
import OnlyWorkingGamesSwitch from 'components/OnlyWorkingGamesSwitch/OnlyWorkingGamesSwitch';
import { getGames } from 'api/Game';
import { Game } from 'types/gameService';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { setCurrentPage } from 'store/slices/currentPageSlice';
import { toggleEnvsPageWorkingGames } from 'store/slices/workingGamesFilterSlice';
import { filterWorkingGames } from 'utils/game';
import styles from './emvironmentPage.module.scss';
import { PAGE } from 'consts/constants';
import { usePageAllowedEnvironments } from 'hooks/usePageAllowedEnvironments';

const EnvironmentPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOnlyWorkingGamesOnEnvsPage } = useAppSelector((state) => state.workingGamesFilter);

  const [environment, setEnvironment] = useState<string>('');
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { allowedEnvironments, initEnvironment } = usePageAllowedEnvironments(PAGE.ENVIRONMENTS);

  const handleChangeEnvironment = (event: SelectChangeEvent<string>): void => {
    setEnvironment(event.target.value);
  };

  const handleStatusGame = (): void => {
    dispatch(toggleEnvsPageWorkingGames());
  };

  useEffect(() => {
    dispatch(setCurrentPage(PAGE.ENVIRONMENTS));
  }, [dispatch]);

  useEffect(() => {
    setEnvironment(initEnvironment);
  }, [initEnvironment]);

  useEffect(() => {
    (async function fetchGames() {
      if (!environment) return;

      setLoading(true);
      try {
        const response = await getGames(environment);
        setGames(response.data.games);
      } catch (error) {
        console.error((error as Error).message);
      } finally {
        setLoading(false);
      }
    })();
  }, [environment]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={styles.formGroup}>
        <OnlyWorkingGamesSwitch
          checked={isOnlyWorkingGamesOnEnvsPage}
          onChange={handleStatusGame}
          disabled={loading}
        />
        <EnvironmentSelect
          environment={environment}
          handleChangeEnvironment={handleChangeEnvironment}
          environments={allowedEnvironments}
          disabled={loading}
        />
      </div>
      {loading ? (
        <Preloader />
      ) : (
        <GamesTable games={isOnlyWorkingGamesOnEnvsPage ? filterWorkingGames(games) : games} />
      )}
    </ErrorBoundary>
  );
};

export default EnvironmentPage;
