import React, { useState, useEffect } from 'react';
import { styled, TableCell, tableCellClasses, Autocomplete, TextField, Chip } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { GameInfo, GameTag, Tag } from 'types/adminService';

import styles from './gameTagsTableRow.module.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface GameTagsTableRowProps {
  game: GameInfo;
  tags: Tag[];
  handleAddTag: (newTag: GameTag) => void;
  handleDeleteTag: (gameId: number, tagId: number) => Promise<void>;
}

const GameTagsTableRow: React.FC<GameTagsTableRowProps> = ({
  game,
  tags,
  handleAddTag,
  handleDeleteTag,
}) => {
  const [selectedTags, setSelectedTags] = useState<Tag[]>(game.tags);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setSelectedTags(game.tags);
  }, [game.tags]);

  const handleTagChange = (event: React.SyntheticEvent, newValue: Tag[] | null) => {
    if (newValue && newValue.length > selectedTags.length) {
      const addedTag = newValue.find(
        (tag) => !selectedTags.some((selectedTag) => selectedTag.id === tag.id),
      );
      if (addedTag) {
        handleAddTag({ title: addedTag.title, gameId: game.id });
      }
    }
    setSelectedTags(newValue || []);
  };

  const handleDelete = (tagToDelete: Tag) => {
    const newSelectedTags = selectedTags.filter((tag) => tag.id !== tagToDelete.id);
    setSelectedTags(newSelectedTags);
    handleDeleteTag(game.id, tagToDelete.id);
  };

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
  };

  const filteredTags = tags.filter((tag) =>
    tag.title.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const options: Tag[] =
    inputValue && !filteredTags.find((tag) => tag.title.toLowerCase() === inputValue.toLowerCase())
      ? [...filteredTags, { id: 0, title: inputValue, description: '' }]
      : filteredTags;

  return (
    <StyledTableRow>
      <StyledTableCell align="center" component="th" scope="row">
        {game.gameUID}
      </StyledTableCell>
      <StyledTableCell align="center">{game.gameName}</StyledTableCell>
      <StyledTableCell align="center">{game.gameClass}</StyledTableCell>
      <StyledTableCell align="center" className={styles.tagsCell}>
        <Autocomplete
          multiple
          value={selectedTags}
          onChange={handleTagChange}
          onInputChange={handleInputChange}
          options={options}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id && option.title === value.title
          }
          getOptionLabel={(tag: Tag) => tag.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tags"
              variant="outlined"
              placeholder="Select or add tags"
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {option.title}
              {option.id === 0 && <span className={styles.newTagText}>(new tag)</span>}
            </li>
          )}
          renderTags={(value) =>
            value.map((tag, index) => (
              <Chip
                key={tag.id}
                label={tag.title}
                onDelete={() => handleDelete(tag)}
                style={{ margin: '2px' }}
              />
            ))
          }
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default GameTagsTableRow;
