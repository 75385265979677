import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useFetchGames from 'hooks/useFetchGames';
import { fetchGameURL, setCurrentGameURL } from 'store/slices/currentGameSlice';
import { fetchUserBalance } from 'store/slices/userDataSlice';
import { Main } from './Main';
import { Iframe } from 'components/Iframe';
import {
  DEFAULT_SELECTED_LICENSED_SITE,
  EXIT_URL,
  GAME_MODE,
  INTEGRATION,
  INTEGRATION_BACKEND,
  LANGUAGE,
  PERMISSION_TITLE,
} from 'consts/constants';
import { EnvLanguage, RunGameParameters } from 'types/gameService';
import { getLanguagesInfo } from 'api/Game';
import { Preloader } from 'components/Preloader/Preloader';
import CreditForm from 'components/CreditForm';
import { setGlobalLanguage, setSelectedLicensedSite } from 'store/slices/configUISlice';
import { getLicensedSiteList } from 'api/Backend';
import { LicensedSite } from 'types/backendService';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';
import { fetchConfiguredCurrencies } from 'store/slices/currencySlice';
import { ConfiguredCurrency } from 'types/configuredCurrency';
import { hideSnackbar, showSnackbar } from 'store/slices/snackbarSlice';
import { useHasPermission } from 'hooks/useHasPermission';
import axios from 'axios';

export const MainContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const URLGame = useAppSelector((state) => state.currentGame.URL);
  const { currentCurrency, funCurrencyConfig, configuredCurrencies } = useAppSelector(
    (state) => state.currencyState,
  );
  const { error } = useAppSelector((state) => state.currentGame);
  const {
    real: { value: realBalance },
    fun: { value: funBalance },
  } = useAppSelector((state) => state.user.currentBalanceData);
  const {
    integrationBackend,
    selectedLicensedSite,
    workingGamesSwitcherStates: { main: isOnlyWorkingGames },
    sortOrder,
    categorySort,
    isOnlyClassesA,
    service,
    environment,
    isFullScreenMode,
    globalLanguage,
    isBaseInterface,
  } = useAppSelector((state) => state.stateUI);
  const { open, message, severity } = useAppSelector((state) => state.snackbar);

  const [searchedGameName, setSearchedGameName] = useState<string>('');
  const [isActiveIframe, setIsActiveIframe] = useState<boolean>(false);
  const [isOpenCreditForm, setIsOpenCreditForm] = useState<boolean>(false);
  const [isLoadingGames, games, changeEnvironment] = useFetchGames(environment);
  const [isLoadingEnvLanguageInfo, setIsLoadingEnvLanguageInfo] = useState<boolean>(true);
  const [envLanguages, setEnvLanguages] = useState<EnvLanguage[]>([]);
  const [isLoadingLicensedSites, setIsLoadingLicensedSites] = useState<boolean>(true);
  const [licensedSites, setLicensedSites] = useState<LicensedSite[]>([]);
  const [currentGameRealCurrency, setCurrentGameRealCurrency] = useState<
    ConfiguredCurrency | undefined
  >(currentCurrency);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const isMisSettledTransactionAllowed = useHasPermission([
    PERMISSION_TITLE.MISSETTLED_TRANSACTION,
  ]);

  useEffect(() => {
    if (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    }
  }, [dispatch, error]);

  useEffect(() => {
    dispatch(fetchConfiguredCurrencies(environment));
  }, [dispatch, environment]);

  useEffect(() => {
    changeEnvironment(environment);
  }, [changeEnvironment, environment]);

  useEffect(() => {
    if (!URLGame) return;

    if (isFullScreenMode) {
      handleFullScreenGame(URLGame, isBaseInterface);
      dispatch(setCurrentGameURL(''));
    } else {
      setIsActiveIframe(true);
    }
  }, [URLGame, dispatch, isBaseInterface, isFullScreenMode]);

  function handleFullScreenGame(url: string, isBaseInterface: boolean): void {
    if (isBaseInterface) {
      window.open(url, '_blank');
    } else {
      window.location.assign(url);
    }
  }

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchEnvironmentLanguages = async () => {
      setIsLoadingEnvLanguageInfo(true);

      try {
        const response = await getLanguagesInfo(environment, signal);
        const { languages } = response.data;

        setEnvLanguages(languages);
        const language = languages.some((language) => language.id === globalLanguage)
          ? globalLanguage
          : LANGUAGE.EN;

        dispatch(setGlobalLanguage(language));
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        console.error((error as Error).message);
      } finally {
        setIsLoadingEnvLanguageInfo(false);
      }
    };

    fetchEnvironmentLanguages();

    return () => {
      controller.abort();
    };
  }, [dispatch, environment, globalLanguage]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchLicensedSiteList = async () => {
      setIsLoadingLicensedSites(true);

      try {
        const response = await getLicensedSiteList(environment, integrationBackend, signal);
        const { sites } = response.data;
        setLicensedSites(sites);

        const selectedSiteName = getSelectedSiteName(sites, selectedLicensedSite);
        dispatch(setSelectedLicensedSite(selectedSiteName));
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        console.error((error as Error).message);
      } finally {
        setIsLoadingLicensedSites(false);
      }
    };

    const getSelectedSiteName = (sites: LicensedSite[], licensedSite: string) => {
      if (sites.some((site) => site.name === licensedSite)) {
        return licensedSite;
      }
      if (sites.some((site) => site.name === DEFAULT_SELECTED_LICENSED_SITE.NAME)) {
        return DEFAULT_SELECTED_LICENSED_SITE.NAME;
      }
      return sites[0].name;
    };

    fetchLicensedSiteList();

    return () => {
      controller.abort();
    };
  }, [dispatch, environment, integrationBackend, selectedLicensedSite]);

  const runGame = (runGameParameters: RunGameParameters): void => {
    setRealCurrencyForIframe(runGameParameters.currency);
    if (integrationBackend === INTEGRATION_BACKEND.LEGACY) {
      runLegacyIntegrationBackendGame(runGameParameters);
    } else {
      runOSIIntegrationBackendGame(runGameParameters);
    }
  };

  const runLegacyIntegrationBackendGame = ({
    mode,
    gameUid,
    version,
    language,
    currency,
  }: RunGameParameters) => {
    dispatch(
      fetchGameURL({
        integrationBackend: INTEGRATION_BACKEND.LEGACY,
        urlParams: {
          gameUid,
          mode,
          currency,
          language,
          version,
          gameBackend: service,
          env: environment,
          site: selectedLicensedSite,
        },
      }),
    );
  };

  const runOSIIntegrationBackendGame = ({
    mode,
    gameUid,
    version,
    language,
    currency,
  }: RunGameParameters) => {
    const amount = mode === GAME_MODE.REAL ? realBalance : funBalance;

    dispatch(
      fetchGameURL({
        integrationBackend: INTEGRATION_BACKEND.OSI,
        urlParams: {
          gameUid,
          mode,
          currency,
          integration: INTEGRATION.SEAMLESS,
          language,
          mobile: isMobile,
          amount,
          exitUrl: EXIT_URL,
          version,
          env: environment,
        },
      }),
    );
  };

  const closeModalIframe = (status: boolean): void => {
    setIsActiveIframe(status);
    dispatch(setCurrentGameURL(''));
    dispatch(fetchUserBalance());
  };

  const handleClickOpenCreditForm = () => {
    setIsOpenCreditForm(true);
  };

  const sendMessageToIframe = (): void => {
    const message = { name: 'updateBalance' };
    const currentIframe = iframeRef.current;

    if (currentIframe && currentIframe.contentWindow) {
      currentIframe.contentWindow.postMessage(message, '*');
    }
  };

  const handleAsyncAction = () => {
    dispatch(fetchUserBalance());
  };

  const handleAlertClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') {
      dispatch(hideSnackbar());
    }
  };

  const setRealCurrencyForIframe = (currency: string) => {
    const configuredRealCurrency = configuredCurrencies.find(
      (configuredCurrency) => configuredCurrency.id === currency,
    );

    setCurrentGameRealCurrency(configuredRealCurrency);
  };

  const isLoading = isLoadingGames && isLoadingEnvLanguageInfo && isLoadingLicensedSites;

  return (
    <main className="main">
      {isLoading ? (
        <Preloader />
      ) : (
        <Main
          searchedGameName={searchedGameName}
          setSearchedGameName={setSearchedGameName}
          games={games}
          runGame={runGame}
          isOnlyWorkingGames={isOnlyWorkingGames}
          isOnlyClassesA={isOnlyClassesA}
          sortOrder={sortOrder}
          categorySort={categorySort}
          envLanguages={envLanguages}
          licensedSites={licensedSites}
          isMisSettledTransactionAllowed={isMisSettledTransactionAllowed}
        />
      )}
      {URLGame && isActiveIframe && (
        <div>
          <Iframe
            URLGame={URLGame}
            closeModalIframe={closeModalIframe}
            handleClickOpenCreditForm={handleClickOpenCreditForm}
            isBaseInterface={isBaseInterface}
            iframeRef={iframeRef}
          />
          {currentGameRealCurrency && funCurrencyConfig && (
            <CreditForm
              userAPIType="AuthenticatedUser"
              isOpenCreditForm={isOpenCreditForm}
              setIsOpenCreditForm={setIsOpenCreditForm}
              realBalance={realBalance}
              funBalance={funBalance}
              realCurrency={currentGameRealCurrency}
              funCurrencyConfig={funCurrencyConfig}
              asyncAction={handleAsyncAction}
              sendMessageToIframe={sendMessageToIframe}
            />
          )}
        </div>
      )}
      <CustomSnackbar
        open={open}
        onClose={handleAlertClose}
        severity={severity}
        message={message}
      />
    </main>
  );
};
