import { ChangeEvent, FormEvent } from 'react';
import { Button } from '@mui/material';

import styles from './loginForm.module.scss';

interface LoginFromProps {
  loginValue: string;
  handleLogIn: (e: FormEvent) => void;
  handleLoginChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handlePasswordChange: (e: ChangeEvent<HTMLInputElement>) => void;
  passwordValue: string;
}

export const LoginForm: React.FC<LoginFromProps> = ({
  loginValue,
  handleLogIn,
  handleLoginChange,
  handlePasswordChange,
  passwordValue,
}) => {
  return (
    <form className={styles.loginForm} onSubmit={handleLogIn}>
      <h2>Please login</h2>
      <input
        onChange={(event) => handleLoginChange(event)}
        value={loginValue}
        type="text"
        placeholder="login"
        autoComplete="on"
      />
      <input
        onChange={(event) => handlePasswordChange(event)}
        value={passwordValue}
        type="password"
        placeholder="password"
        autoComplete="on"
      />
      <div>
        <Button variant="contained" type="submit">
          LOGIN
        </Button>
      </div>
    </form>
  );
};
